/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import App from "App";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <KindeProvider
        clientId="65185fd3db2e4f3cb74349024205c1dc"
        domain="https://enerscale.kinde.com"
        logoutUri={window.location.origin}
        redirectUri={window.location.origin}
      >
        <App />
      </KindeProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
