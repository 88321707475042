/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @react-jvectormap components
import { VectorMap } from "@react-jvectormap/core";
import { worldMerc } from "@react-jvectormap/world";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SalesTable from "examples/Tables/SalesTable";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import PieChart from "examples/Charts/PieChart";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";

import PropTypes from "prop-types";

import { serverBaseURL, googleMapKey } from "common/settings";
import { getToken } from "common/utils";

import SG from "assets/images/icons/flags/SG.png";
import JP from "assets/images/icons/flags/JP.png";

function GeoMap({ markers }) {
  return (
    <LoadScript googleMapsApiKey={googleMapKey}>
      <GoogleMap
        center={{ lat: 1.29, lng: 103.85 }}
        zoom={10}
        options={{ scrollwheel: false }}
        mapContainerStyle={{ height: `280px`, borderRadius: "6px", overflow: "hidden" }}
      >
        {markers}
      </GoogleMap>
    </LoadScript>
  );
}

GeoMap.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Stack
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      mt={3}
      justifyContent="center"
      {...other}
    >
      {value === index && children}
    </Stack>
  );
}

TabPanel.defaultProps = {
  children: "",
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Analysis() {
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [selectedSiteCount, setSelectedSiteCount] = useState(0);
  const [selectedSitePower, setSelectedSitePower] = useState(0);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [processTime, setProcessTime] = useState("");
  const [status, setStatus] = useState("");
  const [mapTableData, setMapTableData] = useState([
    {
      country: ["", ""],
      red: "",
      yellow: "",
      green: "",
    },
  ]);
  const [mapTableDataProduction, setMapTableDataProduction] = useState([
    {
      country: ["", ""],
      red: "",
      yellow: "",
      green: "",
    },
  ]);
  const [timeLineData, setTimeLineData] = useState({});
  const [timeLineDataProduction, setTimeLineDataProduction] = useState({});
  const [pieChartData, setPieChartData] = useState({
    labels: ["Red", "Yellow", "Green"],
    datasets: {
      label: "Breakdown",
      backgroundColors: ["error", "warning", "success"],
      data: [0, 0, 0],
    },
  });
  const [pieChartDataProduction, setPieChartDataProduction] = useState({
    labels: ["Red", "Yellow", "Green"],
    datasets: {
      label: "Breakdown",
      backgroundColors: ["error", "warning", "success"],
      data: [0, 0, 0],
    },
  });
  const [markers, setMarkers] = useState([]);
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "Select to Publish", accessor: "select" },
      { Header: "Site Name", accessor: "name" },
      { Header: "Site Status", accessor: "status" },
      { Header: "Current PV (kW)", accessor: "power" },
      { Header: "Optimal PV (kW)", accessor: "optimization" },
      { Header: "Best / Worst", accessor: "score" },
      { Header: "Problems", accessor: "problems" },
      { Header: "Degradation P95 exceedance level", accessor: "degradationExceedanceLevel" },
      { Header: "Degradation Confidence level", accessor: "degradationConfidenceLevel" },
      { Header: "Degradation Confidence interval", accessor: "degradationConfidenceInterval" },
      {
        Header: "P50 insolation-weighted soiling ratio",
        accessor: "insolationWeightedSoilingRatio",
      },
      {
        Header: "68.2 confidence interval soiling ratio",
        accessor: "confidenceIntervalSoilingRatio",
      },
      { Header: "Lifetime", accessor: "lifetime" },
      { Header: "Grade", accessor: "grade" },
      { Header: "Category", accessor: "category" },
      { Header: "Action", accessor: "actions" },
    ],

    rows: [],
  });
  const [selectedSite, setSelectedSite] = useState([]);
  const [exceedanceLevelMedian, setExceedanceLevelMedian] = useState(0);
  const [maxConfidenceLevel, setMaxConfidenceLevel] = useState(0);
  const [minConfidenceLevel, setMinConfidenceLevel] = useState(0);
  const [maxWeightedSoilingRatio, setMaxWeightedSoilingRatio] = useState(0);
  const [minWeightedSoilingRatio, setMinWeightedSoilingRatio] = useState(0);
  const [maxLifetime, setMaxLifetime] = useState(1999);
  const [minLifetime, setMinLifetime] = useState(2099);
  const [tabValue, setTabValue] = useState(0);
  // const [yearArray, setYearArray] = useState([]);
  const token = getToken();

  const handleConfirmOpen = () => setConfirmOpen(true);
  const handleConfirmClose = () => setConfirmOpen(false);

  const handleReportOpen = () => setReportOpen(true);
  const handleReportClose = () => setReportOpen(false);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const selectSite = (i) => {
    const item = selectedSite[i];
    if (item) {
      item.selected = !item.selected;
      const temp = Array.from(selectedSite);
      temp[i] = item;
      setSelectedSite(temp);
      const publishSites = temp.filter((x) => x.selected);
      setSelectedSiteCount(publishSites.length);
      const publishSitesPower = publishSites.map((x) => x.pv);
      const publishPower = publishSitesPower.reduce((pre, cur) => pre + cur, 0);
      setSelectedSitePower(publishPower);
    }
  };

  const flags = {
    Singapore: SG,
    Japan: JP,
  };

  async function fetchData() {
    const publishSiteMap = new Map();
    const publishedResponse = await (
      await fetch(`${serverBaseURL}published_sites`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ).json();
    console.log(publishedResponse);
    for (let i = 0; i < publishedResponse.published_sites.length; i += 1) {
      publishSiteMap.set(publishedResponse.published_sites[i].name, true);
    }
    const response = await (
      await fetch(`${serverBaseURL}get_trend_result`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ).json();
    console.log(response);

    setSelectedSite(
      response.sites.map((site) => ({
        ...site,
        published: publishSiteMap.has(site.name),
        selected: false,
      }))
    );

    const median = (arr) => {
      const { length } = arr;

      arr.sort((a, b) => a - b);

      if (length % 2 === 0) {
        return (arr[length / 2 - 1] + arr[length / 2]) / 2;
      }

      return arr[(length - 1) / 2];
    };

    const exceedanceLavelArray = response.sites.map((site) => site.exceedance_level);

    setExceedanceLevelMedian(median(exceedanceLavelArray).toFixed(3));

    const confidenceLevelArray = response.sites.map((site) => site.confidence_level);

    setMaxConfidenceLevel(Math.max(...confidenceLevelArray).toFixed(3));
    setMinConfidenceLevel(Math.min(...confidenceLevelArray).toFixed(3));

    const weightedSoilingRatioArray = response.sites.map(
      (site) => site.insolation_weighted_soiling_ratio
    );
    console.log(weightedSoilingRatioArray);
    setMaxWeightedSoilingRatio(Math.max(...weightedSoilingRatioArray).toFixed(3));
    setMinWeightedSoilingRatio(Math.min(...weightedSoilingRatioArray).toFixed(3));

    const mapTableDataList = response.analysis.battery_statistics.map((prop) => ({
      country: [flags[prop[0]], prop[0]],
      red: prop[1],
      yellow: prop[2],
      green: prop[3],
    }));
    const mapTableDataProductionList = response.analysis.production_statistics.map((prop) => ({
      country: [flags[prop[0]], prop[0]],
      red: prop[1],
      yellow: prop[2],
      green: prop[3],
    }));
    setMapTableData(mapTableDataList);
    setMapTableDataProduction(mapTableDataProductionList);
    setTimeLineData({
      labels: response.analysis.trend_battery_graph_data.labels.map((i) => i.split(" ")[0]),
      datasets: [
        {
          label: "Red Trend",
          color: "error",
          data: response.analysis.trend_battery_graph_data.series[0],
        },
        {
          label: "Yellow Trend",
          color: "warning",
          data: response.analysis.trend_battery_graph_data.series[1],
        },
        {
          label: "Green Trend",
          color: "success",
          data: response.analysis.trend_battery_graph_data.series[2],
        },
      ],
    });
    setTimeLineDataProduction({
      labels: response.analysis.trend_production_graph_data.labels.map((i) => i.split(" ")[0]),
      datasets: [
        {
          label: "Red Trend",
          color: "error",
          data: response.analysis.trend_production_graph_data.series[0],
        },
        {
          label: "Yellow Trend",
          color: "warning",
          data: response.analysis.trend_production_graph_data.series[1],
        },
        {
          label: "Green Trend",
          color: "success",
          data: response.analysis.trend_production_graph_data.series[2],
        },
      ],
    });
    setPieChartData({
      labels: ["Red", "Yellow", "Green"],
      datasets: {
        label: "Breakdown",
        backgroundColors: ["error", "warning", "success"],
        data: [
          response.analysis.battery_statistics[0][1],
          response.analysis.battery_statistics[0][2],
          response.analysis.battery_statistics[0][3],
        ],
      },
    });
    setPieChartDataProduction({
      labels: ["Red", "Yellow", "Green"],
      datasets: {
        label: "Breakdown",
        backgroundColors: ["error", "warning", "success"],
        data: [
          response.analysis.production_statistics[0][1],
          response.analysis.production_statistics[0][2],
          response.analysis.production_statistics[0][3],
        ],
      },
    });
    const iconBase = "http://maps.google.com/mapfiles/ms/icons/";
    setMarkers(
      response.sites.map((prop) => {
        const color = prop.status;
        const { coordinate } = prop;
        const [lat, lng] = coordinate.split(",");
        let iconUrl;
        if (color === "Red") {
          iconUrl = `${iconBase}red-dot.png`;
        } else if (color === "Yellow") {
          iconUrl = `${iconBase}yellow-dot.png`;
        } else {
          iconUrl = `${iconBase}green-dot.png`;
        }
        return (
          <Marker
            position={{ lat: Number(lat), lng: Number(lng) }}
            icon={iconUrl}
            key={prop.name}
          />
        );
      })
    );
  }

  const publish = async () => {
    const publishSites = selectedSite.filter((item) => item.selected).map((item) => item.name);
    const publishSiteNames = publishSites.join(":");
    console.log(publishSiteNames);
    const formData = new FormData();
    formData.append("publish_site_names", publishSiteNames);
    const response = await (
      await fetch(`${serverBaseURL}publish`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
    ).json();
    console.log(response);
    handleReportOpen();
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  function randnBm(min, max, skew) {
    let u = 0;
    let v = 0;
    while (u === 0) u = Math.random(); // Converting [0,1) to (0,1)
    while (v === 0) v = Math.random();
    let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);

    num = num / 10.0 + 0.5; // Translate to 0 -> 1
    if (num > 1 || num < 0) {
      num = randnBm(min, max, skew);
      // resample between 0 and 1 if out of range
    } else {
      num **= skew; // Skew
      num *= max - min; // Stretch to fill range
      num += min; // offset to min
    }
    return num;
  }

  const caculateLifetime = (prop) => {
    const installedYear = Number(prop.installed_date.split(" ")[0].split("-")[0]);

    const lifetimeSeries = [];

    let startLifetime = 20;
    lifetimeSeries.push(startLifetime + 80);

    let years = 0;
    do {
      const rand = randnBm(0.1, 1, 1).toFixed(3);
      const de = rand - prop.exceedance_level;
      startLifetime -= de;
      lifetimeSeries.push(startLifetime + 80);
      years += 1;
    } while (startLifetime >= 0);

    // eslint-disable-next-line no-param-reassign
    prop.lifetime_series = lifetimeSeries;
    // eslint-disable-next-line no-param-reassign
    prop.years = years;
    // eslint-disable-next-line no-param-reassign
    prop.installed_year = installedYear;

    return installedYear + years;
  };
  useEffect(() => {
    setTableData({
      columns: [
        { Header: "Select to Publish", accessor: "select" },
        { Header: "Site Name", accessor: "name" },
        { Header: "Site Status", accessor: "status" },
        { Header: "Current PV (kW)", accessor: "power" },
        { Header: "Optimal PV (kW)", accessor: "optimization" },
        { Header: "Best / Worst", accessor: "score" },
        { Header: "Problems", accessor: "problems" },
        { Header: "Degradation P95 exceedance level", accessor: "degradationExceedanceLevel" },
        { Header: "Degradation Confidence level", accessor: "degradationConfidenceLevel" },
        { Header: "Degradation Confidence interval", accessor: "degradationConfidenceInterval" },
        {
          Header: "P50 insolation-weighted soiling ratio",
          accessor: "insolationWeightedSoilingRatio",
        },
        {
          Header: "68.2 confidence interval soiling ratio",
          accessor: "confidenceIntervalSoilingRatio",
        },
        { Header: "Lifetime", accessor: "lifetime" },
        { Header: "Grade", accessor: "grade" },
        { Header: "Category", accessor: "category" },
        { Header: "Action", accessor: "actions" },
      ],

      rows: selectedSite.map((prop, key) => ({
        id: key,
        select: prop.published ? null : (
          <Switch checked={prop.selected} onClick={() => selectSite(key)} />
        ),
        name: prop.name,
        status: prop.status,
        power: prop.pv,
        optimization: Number(prop.optimal_pv).toFixed(2),
        score: prop.score,
        problems: prop.problems,
        degradationExceedanceLevel: (
          <MDButton
            onClick={() => {
              navigate("/dashboards/analysis-detail", { state: { name: prop.name } });
            }}
          >
            {prop.exceedance_level.toFixed(3)}%/yr
          </MDButton>
        ),
        degradationConfidenceLevel: (
          <MDButton
            onClick={() => {
              navigate("/dashboards/analysis-detail", { state: { name: prop.name } });
            }}
          >
            {prop.confidence_level.toFixed(3)}%/yr
          </MDButton>
        ),
        degradationConfidenceInterval: (
          <MDButton
            onClick={() => {
              navigate("/dashboards/analysis-detail", { state: { name: prop.name } });
            }}
          >
            {prop.confidence_interval[0].toFixed(3)} to {prop.confidence_interval[1].toFixed(3)}
          </MDButton>
        ),
        insolationWeightedSoilingRatio: (
          <MDButton
            onClick={() => {
              navigate("/dashboards/analysis-detail", { state: { name: prop.name } });
            }}
          >
            {prop.insolation_weighted_soiling_ratio.toFixed(3)}
          </MDButton>
        ),
        confidenceIntervalSoilingRatio: (
          <MDButton
            onClick={() => {
              navigate("/dashboards/analysis-detail", { state: { name: prop.name } });
            }}
          >
            {prop.confidence_interval_soiling_ratio[0].toFixed(3)}-
            {prop.confidence_interval_soiling_ratio[1].toFixed(3)}
          </MDButton>
        ),
        lifetime: (
          <MDButton
            onClick={() => {
              navigate("/dashboards/lifetime", {
                state: {
                  prop,
                },
              });
            }}
          >
            {caculateLifetime(prop)}
          </MDButton>
        ),
        grade: prop.grade,
        category: prop.category,
        actions: (
          <div className="actions-right">
            <MDButton
              onClick={() => {
                const [lat, lng] = prop.coordinate.split(",");
                const markerArray = [];
                markerArray.push([prop.status, lat, lng]);
                navigate("/dashboards/map", { state: { markerData: markerArray } });
              }}
            >
              <Icon fontSize="medium" color="primary">
                map
              </Icon>
            </MDButton>
          </div>
        ),
      })),
    });
  }, [selectedSite]);

  useEffect(() => {
    async function fetchTaskStatus() {
      const statusResponse = await (
        await fetch(`${serverBaseURL}caculate_status`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      ).json();
      setStatus(statusResponse.task_status);
      setStartTime(statusResponse.start_time.split(".")[0]);
      setEndTime(statusResponse.end_time.split(".")[0]);
      setProcessTime(statusResponse.process_time);
    }
    fetchTaskStatus();
    setMinLifetime(2021);
    setMaxLifetime(2053);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Modal
          open={confirmOpen}
          onClick={handleConfirmClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <MDBox
            p={5}
            position="absolute"
            color="white"
            bgColor="white"
            transform="translate(-50%, -50%)"
          >
            <MDTypography variant="h5">
              Realy publish {selectedSiteCount} sites(Total output: {selectedSitePower} kW)?
            </MDTypography>
            <br />
            <Stack spacing={5} direction="row">
              <MDButton
                p={5}
                variant="gradient"
                color="dark"
                onClick={() => {
                  publish();
                  handleConfirmClose();
                }}
              >
                Yes
              </MDButton>
              <MDButton
                p={5}
                variant="gradient"
                color="dark"
                onClick={() => {
                  handleConfirmClose();
                }}
              >
                No
              </MDButton>
            </Stack>
          </MDBox>
        </Modal>
        <Modal
          open={reportOpen}
          onClick={handleReportClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <MDBox
            p={5}
            position="absolute"
            color="white"
            bgColor="white"
            transform="translate(-50%, -50%)"
          >
            <MDTypography variant="h5">
              Published {selectedSiteCount} sites(Total output: {selectedSitePower} kW).
            </MDTypography>
            <br />
            <Stack spacing={5} direction="row">
              <MDButton
                p={5}
                variant="gradient"
                color="dark"
                onClick={() => {
                  handleReportClose();
                }}
              >
                OK
              </MDButton>
            </Stack>
          </MDBox>
        </Modal>
        <br />
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">Latest Task</MDTypography>
              <MDTypography variant="h6">Status: {status}</MDTypography>
              <MDTypography variant="h6">startTime: {startTime}</MDTypography>
              <MDTypography variant="h6">endTime: {endTime}</MDTypography>
              <MDTypography variant="h6">processTime: {processTime}</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mt={3} mb={1}>
              <MDTypography variant="h5">System Status - All Regions</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} />
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                <Tab label="Production-based" />
                <Tab label="Battery-based" />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        <TabPanel value={tabValue} index={0}>
          <MDBox mt={5} mb={1}>
            <Card sx={{ width: "100%" }}>
              <MDBox display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor="success"
                  color="white"
                  shadow="md"
                  borderRadius="xl"
                  ml={3}
                  mt={-2}
                >
                  <Icon fontSize="medium" color="inherit">
                    language
                  </Icon>
                </MDBox>
                <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                  System Status
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Grid container>
                  <Grid item xs={12} md={7} lg={6}>
                    <SalesTable rows={mapTableDataProduction} shadow={false} />
                  </Grid>
                  <Grid item xs={12} md={5} lg={6} sx={{ mt: { xs: 5, lg: 0 } }}>
                    <VectorMap
                      map={worldMerc}
                      zoomOnScroll={false}
                      zoomButtons={false}
                      markersSelectable
                      backgroundColor="transparent"
                      markers={[
                        {
                          name: "Singapore",
                          latLng: [1.29027, 103.851959],
                        },
                      ]}
                      regionStyle={{
                        initial: {
                          fill: "#dee2e7",
                          "fill-opacity": 1,
                          stroke: "none",
                          "stroke-width": 0,
                          "stroke-opacity": 0,
                        },
                      }}
                      markerStyle={{
                        initial: {
                          fill: "#e91e63",
                          stroke: "#ffffff",
                          "stroke-width": 5,
                          "stroke-opacity": 0.5,
                          r: 7,
                        },
                        hover: {
                          fill: "E91E63",
                          stroke: "#ffffff",
                          "stroke-width": 5,
                          "stroke-opacity": 0.5,
                        },
                        selected: {
                          fill: "E91E63",
                          stroke: "#ffffff",
                          "stroke-width": 5,
                          "stroke-opacity": 0.5,
                        },
                      }}
                      style={{
                        marginTop: "-1.5rem",
                      }}
                      onRegionTipShow={() => false}
                      onMarkerTipShow={() => false}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
          <Grid container alignItems="center">
            <Grid item xs={12} md={7}>
              <MDBox mt={3} mb={1}>
                <MDTypography variant="h5">System Status - By Country</MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mt={5} mb={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={7}>
                <DefaultLineChart
                  icon={{ component: "show_chart" }}
                  title="Time Series"
                  description="&nbsp;"
                  chart={timeLineDataProduction}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <PieChart
                  icon={{ color: "success", component: "donut_small" }}
                  title="Pie chart"
                  description="Analytics Insights"
                  chart={pieChartDataProduction}
                />
              </Grid>
            </Grid>
          </MDBox>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <MDBox mt={5} mb={1}>
            <Card sx={{ width: "100%" }}>
              <MDBox display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor="success"
                  color="white"
                  shadow="md"
                  borderRadius="xl"
                  ml={3}
                  mt={-2}
                >
                  <Icon fontSize="medium" color="inherit">
                    language
                  </Icon>
                </MDBox>
                <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                  System Status
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Grid container>
                  <Grid item xs={12} md={7} lg={6}>
                    <SalesTable rows={mapTableData} shadow={false} />
                  </Grid>
                  <Grid item xs={12} md={5} lg={6} sx={{ mt: { xs: 5, lg: 0 } }}>
                    <VectorMap
                      map={worldMerc}
                      zoomOnScroll={false}
                      zoomButtons={false}
                      markersSelectable
                      backgroundColor="transparent"
                      markers={[
                        {
                          name: "Singapore",
                          latLng: [1.29027, 103.851959],
                        },
                      ]}
                      regionStyle={{
                        initial: {
                          fill: "#dee2e7",
                          "fill-opacity": 1,
                          stroke: "none",
                          "stroke-width": 0,
                          "stroke-opacity": 0,
                        },
                      }}
                      markerStyle={{
                        initial: {
                          fill: "#e91e63",
                          stroke: "#ffffff",
                          "stroke-width": 5,
                          "stroke-opacity": 0.5,
                          r: 7,
                        },
                        hover: {
                          fill: "E91E63",
                          stroke: "#ffffff",
                          "stroke-width": 5,
                          "stroke-opacity": 0.5,
                        },
                        selected: {
                          fill: "E91E63",
                          stroke: "#ffffff",
                          "stroke-width": 5,
                          "stroke-opacity": 0.5,
                        },
                      }}
                      style={{
                        marginTop: "-1.5rem",
                      }}
                      onRegionTipShow={() => false}
                      onMarkerTipShow={() => false}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
          <Grid container alignItems="center">
            <Grid item xs={12} md={7}>
              <MDBox mt={3} mb={1}>
                <MDTypography variant="h5">System Status - By Country</MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mt={5} mb={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={7}>
                <DefaultLineChart
                  icon={{ component: "show_chart" }}
                  title="Time Series"
                  description="&nbsp;"
                  chart={timeLineData}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <PieChart
                  icon={{ color: "success", component: "donut_small" }}
                  title="Pie chart"
                  description="Analytics Insights"
                  chart={pieChartData}
                />
              </Grid>
            </Grid>
          </MDBox>
        </TabPanel>
        <MDBox mt={5}>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="success"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  map
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Status Map
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <GeoMap markers={markers} />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <br />
        </MDBox>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mt={3} mb={1}>
              <MDTypography variant="h5">Life time trend</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="grade"
                  title="Median Degradation P95 Exceedance Level"
                  description=""
                  value={exceedanceLevelMedian}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="grade"
                  title="Maximum Degradation Confidence Level"
                  description=""
                  value={maxConfidenceLevel}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="grade"
                  title="Minimum Degradation Confidence Level"
                  description=""
                  value={minConfidenceLevel}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="grade"
                  title="Maximum P50 insolation-weighted soiling ratio"
                  description=""
                  value={maxWeightedSoilingRatio}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="grade"
                  title="Minimum P50 insolation-weighted soiling ratio"
                  description=""
                  value={minWeightedSoilingRatio}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="grade"
                  title="Maximum Lifetime"
                  description=""
                  value={maxLifetime}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="grade"
                  title="Minimum Lifetime"
                  description=""
                  value={minLifetime}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={5}>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="success"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  assignment
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Site Status Table
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <DataTable table={tableData} canSearch />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <br />
        </MDBox>
        <Grid container alignItems="right" spacing={5}>
          <Grid item xs={4} md={4} lg={4}>
            <MDBox mb={1}> </MDBox>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <MDBox mb={1}> </MDBox>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <MDBox mb={1}>
              <MDButton variant="gradient" color="dark" onClick={handleConfirmOpen}>
                Publish
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analysis;
