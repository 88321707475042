import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import AppBar from "@mui/material/AppBar";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import DataTable from "examples/Tables/DataTable";

import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import { serverBaseURL } from "common/settings";
import { getToken } from "common/utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Stack
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      mt={3}
      justifyContent="center"
      {...other}
    >
      {value === index && children}
    </Stack>
  );
}

TabPanel.defaultProps = {
  children: "",
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function AnalysisDetail() {
  const location = useLocation();
  const [tabValue, setTabValue] = useState(0);

  const [aggregateHelpOpen, setAggregateHelpOpen] = useState(false);
  const [degradationHelpOpen, setDegradationHelpOpen] = useState(false);

  const [monthlySoilingData, setMonthlySoilingData] = useState({});
  const [yearlyDegradationAndSoilingData, setYearlyDegradationAndSoilingData] = useState({});
  const [aggregateData, setAggregateData] = useState({});
  const [clearskyAggregateData, setClearskyAggregateData] = useState({});
  const [sensorbasedDegradationData, setSensorbasedDegradationData] = useState({});
  const [clearskySensorbasedDegradationData, setClearskySensorbasedDegradationData] = useState({});
  const [confidenceIntervalData, setConfidenceIntervalData] = useState({});
  const [clearskyConfidenceIntervalData, setClearskyConfidenceIntervalData] = useState({});
  const [soilingProfileData, setSoilingProfileData] = useState({});
  const [soilingProfile2Data, setSoilingProfile2Data] = useState({});
  const [soilingIntervalData, setSoilingIntervalData] = useState({
    columns: [
      { Header: "Start", accessor: "start" },
      { Header: "End", accessor: "end" },
      { Header: "Soling rate", accessor: "soilingRate" },
      { Header: "Soling rate low", accessor: "soilingRateLow" },
      { Header: "Soling rate high", accessor: "soilingRateHigh" },
      { Header: "Inferred start loss", accessor: "inferredStartLoss" },
      { Header: "Inferred end loss", accessor: "inferredEndLoss" },
      { Header: "Length", accessor: "length" },
      { Header: "Valid", accessor: "valid" },
    ],
    rows: [],
  });
  const [soilingMonthlyData, setSoilingMonthlyData] = useState({
    columns: [
      { Header: "Month", accessor: "month" },
      { Header: "Soiling rate median", accessor: "soilingRateMedian" },
      { Header: "Soling rate low", accessor: "soilingRateLow" },
      { Header: "Soling rate high", accessor: "soilingRateHigh" },
      { Header: "Interval count", accessor: "intervalCount" },
    ],
    rows: [],
  });
  const [soilingAnnualData, setSoilingAnnualData] = useState({
    columns: [
      { Header: "Year", accessor: "year" },
      { Header: "Soling rate Median", accessor: "soilingRateMedian" },
      { Header: "Soling rate low", accessor: "soilingRateLow" },
      { Header: "Soling rate high", accessor: "soilingRateHigh" },
    ],
    rows: [],
  });
  const [histogramData, setHistogramData] = useState({});
  const [sr, setSr] = useState(0);
  const [srCi, setSrCi] = useState([0, 0]);
  const [exceedanceLevel, setExceedanceLevel] = useState(0);
  const [yoyRd, setYoyRd] = useState(0);
  const [yoyCi, setYoyCi] = useState([0, 0]);
  const [cumulativeDegradation, setCumulativeDegradation] = useState(0);
  const [latestYearDegradation, setLatestYearDegradation] = useState(0);
  const [clearskyExceedanceLevel, setClearskyExceedanceLevel] = useState(0);
  const [clearskyYoyRd, setClearskyYoyRd] = useState(0);
  const [clearskyYoyCi, setClearskyYoyCi] = useState([0, 0]);
  const [clearskyCumulativeDegradation, setClearskyCumulativeDegradation] = useState(0);
  const [clearskyLatestYearDegradation, setClearskyLatestYearDegradation] = useState(0);

  const handleAggregateHelpOpen = () => setAggregateHelpOpen(true);
  const handleAggregateHelpClose = () => setAggregateHelpOpen(false);
  const handleDegradationHelpOpen = () => setDegradationHelpOpen(true);
  const handleDegradationHelpClose = () => setDegradationHelpOpen(false);

  const token = getToken();

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  useEffect(async () => {
    const response = await (
      await fetch(
        `${serverBaseURL}get_site_detail_data?system_type=ongrid&site_name=${location.state.name}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ).json();
    console.log(response);
    setSr(response.degradation_and_soiling_data.sr);
    setSrCi(response.degradation_and_soiling_data.sr_ci);
    setExceedanceLevel(response.degradation_and_soiling_data.exceedance_level);
    setYoyRd(response.degradation_and_soiling_data.yoy_rd);
    setYoyCi(response.degradation_and_soiling_data.yoy_ci);
    setCumulativeDegradation(
      response.exceedance_levels.map((i) => i[1]).reduce((r, i) => r + i, 0)
    );
    setLatestYearDegradation(response.exceedance_levels.slice(-1)[0][1]);
    setClearskyExceedanceLevel(response.clearsky_data.exceedance_level);
    setClearskyYoyRd(response.clearsky_data.yoy_rd);
    setClearskyYoyCi(response.clearsky_data.yoy_ci);
    setClearskyCumulativeDegradation(
      response.clearsky_data.exceedance_levels.map((i) => i[1]).reduce((r, i) => r + i, 0)
    );
    setClearskyLatestYearDegradation(response.clearsky_data.exceedance_levels.slice(-1)[0][1]);

    setMonthlySoilingData({
      labels: response.degradation_and_soiling_data.monthly_soiling_rates.map((i) => i[0]),
      datasets: [
        {
          label: "soiling",
          color: "dark",
          data: response.degradation_and_soiling_data.monthly_soiling_rates.map((i) =>
            i[1].toFixed(3)
          ),
        },
      ],
    });

    setYearlyDegradationAndSoilingData({
      labels: response.degradation_and_soiling_data.annual_soiling_ratios.map((i) => i[0]),
      datasets: [
        {
          label: "soiling",
          color: "dark",
          data: response.degradation_and_soiling_data.annual_soiling_ratios.map((i) =>
            i[1].toFixed(3)
          ),
        },
        {
          label: "degradation",
          color: "info",
          data: response.exceedance_levels,
        },
      ],
    });

    setAggregateData({
      labels: response.degradation_and_soiling_data.aggregate_label,
      datasets: [
        {
          label: "",
          color: "dark",
          data: response.degradation_and_soiling_data.aggregate_data,
        },
      ],
    });

    setClearskyAggregateData({
      labels: response.clearsky_data.aggregate_label,
      datasets: [
        {
          label: "",
          color: "dark",
          data: response.clearsky_data.aggregate_data,
        },
      ],
    });

    setSensorbasedDegradationData({
      labels: response.degradation_and_soiling_data.degradation_renormalized_label,
      datasets: [
        {
          label: "",
          color: "dark",
          data: response.degradation_and_soiling_data.degradation_renormalized_data,
        },
      ],
    });
    setConfidenceIntervalData({
      labels: response.degradation_and_soiling_data.degradation_hist_label.map((i) => i.toFixed(3)),
      datasets: [
        {
          label: "",
          color: "dark",
          data: response.degradation_and_soiling_data.degradation_hist_data,
        },
      ],
    });

    setClearskySensorbasedDegradationData({
      labels: response.clearsky_data.degradation_renormalized_label,
      datasets: [
        {
          label: "",
          color: "dark",
          data: response.clearsky_data.degradation_renormalized_data,
        },
      ],
    });
    setClearskyConfidenceIntervalData({
      labels: response.clearsky_data.degradation_hist_label.map((i) => i.toFixed(3)),
      datasets: [
        {
          label: "",
          color: "dark",
          data: response.clearsky_data.degradation_hist_data,
        },
      ],
    });

    const profiles = response.degradation_and_soiling_data.stochastic_soiling_profiles.map((i) => ({
      label: "",
      color: "dark",
      data: i,
    }));
    setSoilingProfileData({
      labels: response.degradation_and_soiling_data.renormalized_label,
      datasets: [
        {
          label: "",
          color: "dark",
          data: response.degradation_and_soiling_data.renormalized_data,
        },
        ...profiles,
      ],
    });

    setSoilingProfile2Data({
      labels: response.degradation_and_soiling_data.renormalized_label,
      datasets: [
        {
          label: "",
          color: "dark",
          data: response.degradation_and_soiling_data.renormalized_data,
        },
        {
          label: "",
          color: "dark",
          data: response.degradation_and_soiling_data.soiling_ratio_perfect_clean,
        },
      ],
    });

    setSoilingIntervalData({
      columns: [
        { Header: "Start", accessor: "start" },
        { Header: "End", accessor: "end" },
        { Header: "Soiling rate", accessor: "soilingRate" },
        { Header: "Soiling rate low", accessor: "soilingRateLow" },
        { Header: "Soiling rate high", accessor: "soilingRateHigh" },
        { Header: "Inferred start loss", accessor: "inferredStartLoss" },
        { Header: "Inferred end loss", accessor: "inferredEndLoss" },
        { Header: "Length", accessor: "length" },
        { Header: "Valid", accessor: "valid" },
      ],
      rows: response.degradation_and_soiling_data.soiling_interval_summary.map((i) => ({
        start: i[0],
        end: i[1],
        soilingRate: i[2].toFixed(3),
        soilingRateLow: i[3].toFixed(3),
        soilingRateHigh: i[4].toFixed(3),
        inferredStartLoss: i[5].toFixed(3),
        inferredEndLoss: i[6].toFixed(3),
        length: i[7],
        valid: i[8] ? "Yes" : "No",
      })),
    });
    setSoilingMonthlyData({
      columns: [
        { Header: "Month", accessor: "month" },
        { Header: "Soiling rate median", accessor: "soilingRateMedian" },
        { Header: "Soiling rate low", accessor: "soilingRateLow" },
        { Header: "Soiling rate high", accessor: "soilingRateHigh" },
        { Header: "Interval count", accessor: "intervalCount" },
      ],
      rows: response.degradation_and_soiling_data.monthly_soiling_rates.map((i) => ({
        month: i[0],
        soilingRateMedian: i[1].toFixed(3),
        soilingRateLow: i[2].toFixed(3),
        soilingRateHigh: i[3].toFixed(3),
        intervalCount: i[4],
      })),
    });
    setSoilingAnnualData({
      columns: [
        { Header: "Year", accessor: "year" },
        { Header: "Soiling ratio Median", accessor: "soilingRateMedian" },
        { Header: "Soiling ratio low", accessor: "soilingRateLow" },
        { Header: "Soiling ratio high", accessor: "soilingRateHigh" },
      ],
      rows: response.degradation_and_soiling_data.annual_soiling_ratios.map((i) => ({
        year: i[0],
        soilingRateMedian: i[1].toFixed(3),
        soilingRateLow: i[2].toFixed(3),
        soilingRateHigh: i[3].toFixed(3),
      })),
    });
    setHistogramData({
      labels: response.degradation_and_soiling_data.hist_label.map((i) => i.toFixed(3)),
      datasets: [
        {
          label: "Count",
          color: "dark",
          data: response.degradation_and_soiling_data.hist_data,
        },
      ],
    });
  }, []);

  const AggregateHelpButton = (
    <IconButton aria-label="help" onClick={handleAggregateHelpOpen}>
      <QuestionMarkIcon />
    </IconButton>
  );

  const DegradationHelpButton = (
    <IconButton aria-label="help" onClick={handleDegradationHelpOpen}>
      <QuestionMarkIcon />
    </IconButton>
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        open={aggregateHelpOpen}
        onClick={handleAggregateHelpClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <MDBox p={5} color="white" bgColor="white" transform="translate(-50%, -50%)">
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Data is aggregated with an irradiance weighted average. This
              <br />
              can be useful, for example with daily aggregation, to reduce
              <br />
              the impact of high-error data points in the morning and
              <br />
              evening.
            </MDTypography>
          </MDBox>
        </MDBox>
      </Modal>
      <Modal
        open={degradationHelpOpen}
        onClick={handleDegradationHelpClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <MDBox p={5} color="white" bgColor="white" transform="translate(-50%, -50%)">
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Data is then analyzed to estimate the degradation rate
              <br />
              representing the PV system behavior. The results are
              <br />
              visualized and statistics are reported, including the 68.2%
              <br />
              confidence interval, and the P95 exceedance value.
            </MDTypography>
          </MDBox>
        </MDBox>
      </Modal>
      <MDBox pb={3}>
        <MDBox mt={8}>
          <MDTypography mt={2} variant="h3" align="center" sx={{ color: "#484731" }}>
            Monthly soiling rate summary.
          </MDTypography>
        </MDBox>
        <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
          <MDBox width="60rem">
            <GradientLineChart title="" chart={monthlySoilingData} />
          </MDBox>
        </MDBox>
        <MDBox mt={8}>
          <MDTypography mt={2} variant="h3" align="center" sx={{ color: "#484731" }}>
            Yearly degradation and soiling rate summary.
          </MDTypography>
        </MDBox>
        <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
          <MDBox width="60rem">
            <GradientLineChart title="" chart={yearlyDegradationAndSoilingData} />
          </MDBox>
        </MDBox>
        <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
          <Grid container spacing={3} alignItems="center">
            <Grid item md={3} lg={3}>
              <MDBox />
            </Grid>
            <Grid item md={6} lg={6}>
              <AppBar position="static">
                <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                  <Tab
                    label="Aggregate"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        workspaces
                      </Icon>
                    }
                  />
                  <Tab
                    label="Degradation calculation"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        trending_down
                      </Icon>
                    }
                  />
                  <Tab
                    label="Soiling calculations"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        report_problem
                      </Icon>
                    }
                  />
                </Tabs>
              </AppBar>
            </Grid>
            <Grid item md={3} lg={3}>
              <MDBox />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <TabPanel value={tabValue} index={0}>
            <MDTypography mt={2} variant="h3" align="center" sx={{ color: "#484731" }}>
              Aggregate
            </MDTypography>
            <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
              <MDBox width="60rem">
                <GradientLineChart
                  title="An irradiance weighted average"
                  chart={aggregateData}
                  help={AggregateHelpButton}
                />
              </MDBox>
            </MDBox>
            <MDTypography mt={2} variant="h3" align="center" sx={{ color: "#484731" }}>
              Clearsky Aggregate
            </MDTypography>
            <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
              <MDBox width="60rem">
                <GradientLineChart
                  title="An irradiance weighted average"
                  chart={clearskyAggregateData}
                  help={AggregateHelpButton}
                />
              </MDBox>
            </MDBox>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <MDTypography mt={2} variant="h3" align="center" sx={{ color: "#484731" }}>
              Degradation calculation
            </MDTypography>
            <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
              <MDBox width="60rem">
                <Grid container spacing={20}>
                  <Grid item lg={6}>
                    <GradientLineChart
                      title="Sensor-based degradation results"
                      chart={sensorbasedDegradationData}
                      help={DegradationHelpButton}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <VerticalBarChart
                      title="The confidence interval"
                      chart={confidenceIntervalData}
                      help={DegradationHelpButton}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDBox mt={4} width="40rem">
                <Card sx={{ position: "relative", mx: 3, py: 1, px: 1 }} width="60rem">
                  <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      The P95 exceedance level
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {exceedanceLevel.toFixed(3)}%/yr
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      The confidence level
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {yoyRd.toFixed(3)}%/yr
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      The confidence interval
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {yoyCi[0].toFixed(3)} to {yoyCi[1].toFixed(3)} %/yr
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      The latest cumulative degradation rate
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {cumulativeDegradation.toFixed(3)} %
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      The latest year degradation rate
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {latestYearDegradation.toFixed(3)} %
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </MDBox>
            <MDTypography mt={2} variant="h3" align="center" sx={{ color: "#484731" }}>
              Clearsky Degradation calculation
            </MDTypography>
            <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
              <MDBox width="60rem">
                <Grid container spacing={20}>
                  <Grid item lg={6}>
                    <GradientLineChart
                      title="Sensor-based degradation results"
                      chart={clearskySensorbasedDegradationData}
                      help={DegradationHelpButton}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <VerticalBarChart
                      title="The confidence interval"
                      chart={clearskyConfidenceIntervalData}
                      help={DegradationHelpButton}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDBox mt={4} width="40rem">
                <Card sx={{ position: "relative", mx: 3, py: 1, px: 1 }} width="60rem">
                  <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      The P95 exceedance level -- Clearsky
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {clearskyExceedanceLevel.toFixed(3)}%/yr
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      The confidence level -- Clearsky
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {clearskyYoyRd.toFixed(3)}%/yr
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      The confidence interval -- Clearsky
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {clearskyYoyCi[0].toFixed(3)} to {clearskyYoyCi[1].toFixed(3)} %/yr
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      The latest cumulative degradation rate -- Clearsky
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {clearskyCumulativeDegradation.toFixed(3)} %
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      The latest year degradation rate -- Clearsky
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {clearskyLatestYearDegradation.toFixed(3)} %
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </MDBox>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <MDTypography mt={2} variant="h3" align="center" sx={{ color: "#484731" }}>
              Soiling calculation
            </MDTypography>
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDBox mt={4} width="40rem">
                <Card sx={{ position: "relative", mx: 3, py: 1, px: 1 }} width="60rem">
                  <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      The P50 insolation-weighted soiling ratio:
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {sr.toFixed(3)}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      The 68.2 confidence interval for the insolation-weighted soiling ratio
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {srCi[0].toFixed(3)}-{srCi[1].toFixed(3)}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </MDBox>
            <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
              <MDBox width="60rem">
                <Grid container spacing={20}>
                  <Grid item lg={6}>
                    <GradientLineChart
                      title="Plot Monte Carlo realizations of soiling profiles"
                      chart={soilingProfileData}
                      tyva="h6"
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <GradientLineChart
                      title="Plot the slopes for “valid” soiling intervals identified, assuming perfect cleaning events"
                      chart={soilingProfile2Data}
                      tyva="h6"
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
            <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
              <MDBox width="80rem">
                <Card>
                  <MDBox p={3} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                      The soiling interval summary table
                    </MDTypography>
                  </MDBox>
                  <DataTable table={soilingIntervalData} />
                </Card>
              </MDBox>
            </MDBox>
            <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
              <VerticalBarChart title="Histogram of soiling rate" chart={histogramData} />
            </MDBox>
            <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
              <MDBox width="80rem">
                <Card>
                  <MDBox p={3} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                      A monthly soiling rate summary
                    </MDTypography>
                  </MDBox>
                  <DataTable table={soilingMonthlyData} />
                </Card>
              </MDBox>
            </MDBox>
            <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
              <MDBox width="80rem">
                <Card>
                  <MDBox p={3} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Annual insolation-weighted soiling ratios and their confidence intervals
                    </MDTypography>
                  </MDBox>
                  <DataTable table={soilingAnnualData} />
                </Card>
              </MDBox>
            </MDBox>
          </TabPanel>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default AnalysisDetail;
