/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Tutorial from "layouts/pages/tutorial";
import OffGridFileManager from "layouts/pages/offgrid-file-manager";
import OnGridFileManager from "layouts/pages/ongrid-file-manager";
import Overview from "layouts/dashboards/overview";
import ImpactMetrics from "layouts/dashboards/impact-metrics";
import Analysis from "layouts/dashboards/analysis";
import OngridAnalysis from "layouts/ongrid-dashboards/analysis";
import OngridSiteManager from "layouts/ongrid-dashboards/site-manager";
import AnalysisDetail from "layouts/dashboards/analysisdetail";
import Lifetime from "layouts/dashboards/lifetime";
import OngridAnalysisDetail from "layouts/ongrid-dashboards/analysisdetail";
import OngridLifetime from "layouts/ongrid-dashboards/lifetime";
import PowerGenerationForecast from "layouts/ongrid-dashboards/power-generation-forecast";
import EnvironmentalValue from "layouts/ongrid-dashboards/environmental-value";
import Published from "layouts/dashboards/published";
import PredictiveAnalysis from "layouts/dashboards/predictive-analysis";
import Classification from "layouts/dashboards/classification";
import Regression from "layouts/dashboards/regression";
import Map from "layouts/dashboards/map";
import NotSignedIn from "layouts/authentication/not-signed-in";
import SignOutBasic from "layouts/authentication/sign-out";
import CurrentName from "examples/CurrentName";

// Material Dashboard 2 PRO React components
// import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
// import profilePicture from "assets/images/team-3.jpg";

const routes = [
  {
    type: "collapse",
    name: <CurrentName />,
    key: "current-user-name",
    // icon: <MDAvatar src={PersonOutlineIcon} alt="Brooklyn Alice" size="sm" />,
    icon: <Icon fontSize="medium">person_outline</Icon>,
    collapse: [
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-out/basic",
        component: <SignOutBasic />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Tutorial",
    key: "tutorial",
    icon: <Icon fontSize="medium">contact_support</Icon>,
    collapse: [
      {
        name: "Tutorial",
        key: "tutorial",
        route: "/tutorial",
        component: <Tutorial />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Files",
    key: "files",
    icon: <Icon fontSize="medium">publish</Icon>,
    collapse: [
      {
        name: "File Manager",
        key: "file-manager",
        route: "/files/file-manager",
        component: <OffGridFileManager />,
      },
      {
        name: "Site Manager",
        key: "file-site-manager",
        route: "/files/site-manager",
        component: <OngridSiteManager />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Off-Grid Dashboards",
    key: "offgrid-dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Overview",
        key: "overview",
        route: "/dashboards/overview",
        component: <Overview />,
      },
      {
        name: "Impact Metrics",
        key: "impact-metrics",
        route: "/dashboards/impact-metrics",
        component: <ImpactMetrics />,
      },
      {
        name: "Analysis",
        key: "analysis",
        route: "/dashboards/analysis",
        component: <Analysis />,
      },
      {
        name: "Published",
        key: "published",
        route: "/dashboards/published",
        component: <Published />,
      },
      {
        name: "Predictive Analytics",
        key: "predictive-analytics",
        route: "/dashboards/predictive-analytics",
        component: <PredictiveAnalysis />,
      },
      {
        name: "Classification",
        key: "classification",
        route: "/dashboards/classification",
        component: <Classification />,
      },
      {
        name: "Regression",
        key: "regression",
        route: "/dashboards/regression",
        component: <Regression />,
      },
    ],
  },
  {
    type: "collapse",
    name: "On-Grid Dashboards",
    key: "ongrid-dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analysis",
        key: "analysis",
        route: "/ongrid-dashboards/analysis",
        component: <OngridAnalysis />,
      },
      {
        name: "Power generation forecast",
        key: "power-generation-forecast",
        route: "/ongrid-dashboards/power-generation-forecast",
        component: <PowerGenerationForecast />,
      },
      {
        name: "Environmental value",
        key: "environmental-value",
        route: "/ongrid-dashboards/environmental-value",
        component: <EnvironmentalValue />,
      },
    ],
  },
  {
    name: "NotSignedIn",
    key: "not-signed-in",
    redirect: true,
    route: "/authentication/sign-in-page/",
    component: <NotSignedIn />,
  },
  {
    name: "Map",
    key: "map",
    redirect: true,
    route: "/dashboards/map",
    component: <Map />,
  },
  {
    name: "Analysis",
    key: "analysys-detail",
    redirect: true,
    route: "/dashboards/analysis-detail",
    component: <AnalysisDetail />,
  },
  {
    name: "Lifetime",
    key: "lifetime",
    redirect: true,
    route: "/dashboards/lifetime",
    component: <Lifetime />,
  },
  {
    name: "Analysis",
    key: "ongrid-analysys-detail",
    redirect: true,
    route: "/ongrid-dashboards/analysis-detail",
    component: <OngridAnalysisDetail />,
  },
  {
    name: "Lifetime",
    key: "ongrid-lifetime",
    redirect: true,
    route: "/ongrid-dashboards/lifetime",
    component: <OngridLifetime />,
  },
];

export default routes;
