import { useState, useMemo } from "react";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDropzone from "components/MDDropzone";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import PropTypes from "prop-types";

import { serverBaseURL, googleMapKey } from "common/settings";

function Map({ markers }) {
  return (
    <LoadScript googleMapsApiKey={googleMapKey}>
      <GoogleMap
        center={{ lat: 1.29, lng: 103.85 }}
        zoom={10}
        options={{ scrollwheel: false }}
        mapContainerStyle={{ height: `280px`, borderRadius: "6px", overflow: "hidden" }}
      >
        {markers}
      </GoogleMap>
    </LoadScript>
  );
}

Map.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function Classification() {
  const displayMarkers = (prop, key) => {
    const lat = prop[2];
    const lng = prop[3];
    return <Marker position={{ lat: Number(lat), lng: Number(lng) }} key={key} />;
  };
  const [markers, setMarkers] = useState([]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <br />
        <Grid container alignItems="center" spacing={5}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox>
              <MDTypography variant="h5">Classification Upload</MDTypography>
              <MDBox mt={3}>
                <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Classification Upload
                  </MDTypography>
                </MDBox>
                {useMemo(
                  () => (
                    <MDDropzone
                      options={{
                        url: `${serverBaseURL}classification`,
                        XMLHttpRequest: true,
                        addRemoveLinks: true,
                        headers: {
                          "Cache-Control": null,
                          "X-Requested-With": null,
                        },
                        success(file, response) {
                          const responseObj = JSON.parse(response);
                          setMarkers(responseObj.res.map(displayMarkers));
                        },
                      }}
                      autoProcessQueue={false}
                    />
                  ),
                  []
                )}
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={5}>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="success"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  map
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Map
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Map markers={markers} />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <br />
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Classification;
