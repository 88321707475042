/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Navigate } from "react-router-dom";

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

function Basic() {
  const { logout } = useKindeAuth();
  logout();
  return <Navigate to="/authentication/sign-in/" />;
}

export default Basic;
