import { getName, getToken } from "common/utils";
import { useState, useEffect } from "react";

function CurrentName() {
  const [curName, setCurName] = useState("");

  useEffect(() => {
    if (getToken()) {
      setCurName(getName());
    }
  }, []);

  return curName;
}

export default CurrentName;
