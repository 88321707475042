import { useState, useEffect, useMemo } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDDropzone from "components/MDDropzone";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";

import { serverBaseURL } from "common/settings";
import { getToken } from "common/utils";

function FileManager() {
  const [siteDataFiles, setSiteDataFiles] = useState({
    columns: [
      { Header: "File Nawme", accessor: "name" },
      { Header: "Action", accessor: "action", width: "10%" },
    ],

    rows: [],
  });
  const [runningDataFiles, setRunningDataFiles] = useState({
    columns: [
      { Header: "File Nawme", accessor: "name" },
      { Header: "Action", accessor: "action", width: "10%" },
    ],

    rows: [],
  });
  const [generateFinished, setGenerateFinished] = useState(false);

  const handleFinishOpen = () => setGenerateFinished(true);
  const handleFinishClose = () => setGenerateFinished(false);

  const token = getToken();
  const systemType = "offgrid";

  async function deleteFile(type, file, fetchCall) {
    await fetch(`${serverBaseURL}delete_file`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        type,
        systemType,
        file,
      }),
    });
    fetchCall();
  }

  async function fetchFiles() {
    const response = await (
      await fetch(`${serverBaseURL}list_files?systemType=${systemType}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ).json();
    setSiteDataFiles({
      columns: [
        { Header: "File Nawme", accessor: "name" },
        { Header: "Action", accessor: "action", width: "10%" },
      ],

      rows: response.site_files.map((prop) => ({
        name: prop,
        action: (
          <div className="actions-right">
            <MDButton
              color="error"
              onClick={() => {
                deleteFile("sites", prop, fetchFiles);
              }}
            >
              <Icon fontSize="medium" color="white">
                close
              </Icon>
            </MDButton>
          </div>
        ),
      })),
    });

    setRunningDataFiles({
      columns: [
        { Header: "File Nawme", accessor: "name" },
        { Header: "Action", accessor: "action", width: "10%" },
      ],

      rows: response.data_files.map((prop) => ({
        name: prop,
        action: (
          <div className="actions-right">
            <MDButton
              color="error"
              onClick={() => {
                deleteFile("data", prop, fetchFiles);
              }}
            >
              <Icon fontSize="medium" color="white">
                close
              </Icon>
            </MDButton>
          </div>
        ),
      })),
    });
  }

  useEffect(() => {
    fetchFiles();
  }, []);

  async function caculateRequest() {
    handleFinishOpen();
    await (
      await fetch(`${serverBaseURL}caculate_result`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          task_type: systemType,
        }),
      })
    ).json();
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Modal
          open={generateFinished}
          onClick={handleFinishClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <MDBox
            p={5}
            position="absolute"
            color="white"
            bgColor="white"
            transform="translate(-50%, -50%)"
          >
            <MDTypography variant="h5">
              Generate Task Issued. You can check status on other pages.
            </MDTypography>
            <br />
            <Stack spacing={5} direction="row">
              <MDButton
                p={5}
                variant="gradient"
                color="dark"
                onClick={() => {
                  handleFinishClose();
                }}
              >
                OK
              </MDButton>
            </Stack>
          </MDBox>
        </Modal>
        <br />
        <Grid container alignItems="center" spacing={5}>
          <Grid item xs={4} md={4} lg={4}>
            <MDBox mb={1}>
              <MDButton variant="gradient" color="dark" onClick={() => caculateRequest()}>
                Generate Result
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={5}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox>
              <MDTypography variant="h5">Site Data</MDTypography>
              <MDBox mt={3}>
                <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Site Data
                  </MDTypography>
                </MDBox>
                {useMemo(
                  () => (
                    <MDDropzone
                      options={{
                        url: `${serverBaseURL}upload_file`,
                        XMLHttpRequest: true,
                        addRemoveLinks: true,
                        headers: {
                          "Cache-Control": null,
                          "X-Requested-With": null,
                          Authorization: `Bearer ${token}`,
                        },
                        params: (files, xhr, chunk) => {
                          if (chunk) {
                            return {
                              dzuuid: chunk.file.upload.uuid,
                              dzchunkindex: chunk.index,
                              dztotalfilesize: chunk.file.size,
                              dzchunksize: 2 * 1024 * 1024,
                              dztotalchunkcount: chunk.file.upload.totalChunkCount,
                              dzchunkbyteoffset: chunk.index * 2 * 1024 * 1024,
                            };
                          }

                          return {
                            systemType,
                            type: "sites",
                          };
                        },
                        success() {
                          fetchFiles();
                        },
                      }}
                      autoProcessQueue={false}
                    />
                  ),
                  []
                )}
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={5}>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="success"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  assignment
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Site Data Files
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <DataTable table={siteDataFiles} canSearch />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <br />
        </MDBox>
        <Grid container alignItems="center" spacing={5}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox>
              <MDTypography variant="h5">Running Data</MDTypography>
              <MDBox mt={3}>
                <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Running Data
                  </MDTypography>
                </MDBox>
                {useMemo(
                  () => (
                    <MDDropzone
                      options={{
                        url: `${serverBaseURL}upload_file`,
                        XMLHttpRequest: true,
                        addRemoveLinks: true,
                        headers: {
                          "Cache-Control": null,
                          "X-Requested-With": null,
                          Authorization: `Bearer ${token}`,
                        },
                        params: (files, xhr, chunk) => {
                          if (chunk) {
                            return {
                              dzuuid: chunk.file.upload.uuid,
                              dzchunkindex: chunk.index,
                              dztotalfilesize: chunk.file.size,
                              dzchunksize: 2 * 1024 * 1024,
                              dztotalchunkcount: chunk.file.upload.totalChunkCount,
                              dzchunkbyteoffset: chunk.index * 2 * 1024 * 1024,
                            };
                          }

                          return {
                            systemType,
                            type: "data",
                          };
                        },
                        success() {
                          fetchFiles();
                        },
                      }}
                      autoProcessQueue={false}
                    />
                  ),
                  []
                )}
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={5}>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="success"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  assignment
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Runing Data Files
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <DataTable table={runningDataFiles} canSearch />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <br />
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FileManager;
