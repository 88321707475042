import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";

import FormField from "components/FormField";

function CumulativelyNumberOfPeopleWithImprovedEnergyAccess(s, dl, df, dr, h) {
  const innerFomular = (innerS, innerD, innerDr, innerH) =>
    innerS * (1 - innerD / 100) * (1 - innerDr / 100) * innerH;
  return [innerFomular(s, dl, dr, h), innerFomular(s, df, dr, h)];
}

function CurrentlyNumberOfPeopleWithImprovedEnergyAccess(sl, dl, df, dr, h) {
  const innerFomular = (innerSl, innerD, innerDr, innerH) =>
    innerSl * (1 - innerD / 100) * (1 - innerDr / 100) * innerH;
  return [innerFomular(sl, dl, dr, h), innerFomular(sl, df, dr, h)];
}

function NumberOfPeopleWithAccessToTier1EnergyServices(sl, dl, df, dr, h, dt1) {
  const innerFomular = (innerSl, innerD, innerDr, innerH, innerDt1) =>
    innerSl * (1 - innerD / 100) * (1 - innerDr / 100) * innerH * innerDt1;
  return [innerFomular(sl, dl, dr, h, dt1), innerFomular(sl, df, dr, h, dt1)];
}

function NumberOfPeopleWithAccessToTier2EnergyServices(sl, dl, df, dr, h, dt2) {
  const innerFomular = (innerSl, innerD, innerDr, innerH, innerDt2) =>
    innerSl * (1 - innerD / 100) * (1 - innerDr / 100) * innerH * innerDt2;
  return [innerFomular(sl, dl, dr, h, dt2), innerFomular(sl, df, dr, h, dt2)];
}

function PeopleUndertakingMoreEconomicActivity(sl, dl, df, ea, pr) {
  const innerFomular = (innerSl, innerD, innerEa, innerPr) =>
    innerSl * (1 - innerD / 100) * (innerEa / 100) * innerPr;
  return [innerFomular(sl, dl, ea, pr), innerFomular(sl, df, ea, pr)];
}

function PeopleUsingProductsToSupportEnterprise(sl, dl, df, e) {
  const innerFomular = (innerSl, innerD, innerE) => innerSl * (1 - innerD / 100) * (innerE / 100);
  return [innerFomular(sl, dl, e), innerFomular(sl, df, e)];
}

function PeopleThatSpendMoreTimeWorking(sl, dl, df, t, pr) {
  const innerFomular = (innerSl, innerD, innerT, innerPr) =>
    innerSl * (1 - innerD / 100) * (innerT / 100) * innerPr;
  return [innerFomular(sl, dl, t, pr), innerFomular(sl, df, t, pr)];
}

function HouseholdsGeneratingAdditionalIncode(sl, dl, df, ig) {
  const innerFomular = (innerSl, innerD, innerIg) => innerSl * (1 - innerD / 100) * (innerIg / 100);
  return [innerFomular(sl, dl, ig), innerFomular(sl, df, ig)];
}

function CumulativelyAdditionalIncomeGenerated(sl, dl, df, ig, ai, pl) {
  const innerFomular = (innerSl, innerD, innerIg, innerAi, innerPl) =>
    innerSl * (1 - innerD / 100) * ((innerIg / 100) * innerAi * innerPl);
  return [innerFomular(sl, dl, ig, ai, pl), innerFomular(sl, df, ig, ai, pl)];
}

function KeroseneLaternsReplaced(sl, dl, df, r) {
  const innerFomular = (innerSl, innerD, innerR) => innerSl * (1 - innerD / 100) * innerR;
  return [innerFomular(sl, dl, r), innerFomular(sl, df, r)];
}

function Co2EmissionsAvoided(s, dl, df, r, g, pl) {
  const innerFomular = (innerS, innerD, innerR, innerG, innerPl) =>
    innerS * (1 - innerD / 100) * innerR * innerG * innerPl;
  return [innerFomular(s, dl, r, g, pl), innerFomular(s, df, r, g, pl)];
}

function ByHouseholdAdditionalLightHoursUsed(lf, lb, ld, pl) {
  const innerFomular = (innerLf, innerLb, innerLd, innerPl) =>
    (innerLf - innerLb) * innerLd * innerPl;
  return [innerFomular(lf, lb, ld, pl), innerFomular(lf, lb, ld, pl)];
}

function CumulativelyAdditionalLightHoursUsed(s, dl, df, lf, lb, ld, pl) {
  const innerFomular = (innerS, innerD, innerLf, innerLb, innerLd, innerPl) =>
    innerS * (1 - innerD / 100) * ((innerLf - innerLb) * innerLd * innerPl);
  return [innerFomular(s, dl, lf, lb, ld, pl), innerFomular(s, df, lf, lb, ld, pl)];
}

function ByHouseholdChangeInQualityOfLight(bf, bb) {
  const innerFomular = (innerBf, innerBb) => innerBf - innerBb;
  return [innerFomular(bf, bb), innerFomular(bf, bb)];
}

function ByHouseholdSavingsOnEnergyExpenditureForPicoSolar(eb, ef, pl, c, tco) {
  const innerFomular = (innerEb, innerEf, innerPl, innerC) =>
    (innerEb - innerEf) * innerPl - innerC;
  return [innerFomular(eb, ef, pl, c), innerFomular(eb, ef, pl, tco)];
}

function CumulativelySavingsOnEnergyExpenditureForPicoSolar(s, df, ef, eb, pl, c, tco) {
  const innerFomular = (innerS, innerDf, innerEf, innerEb, innerPl, innerC) =>
    innerS * (1 - innerDf / 100) * ((innerEf - innerEb) * innerPl - innerC);
  return [innerFomular(s, df, ef, eb, pl, c), innerFomular(s, df, ef, eb, pl, tco)];
}

function NumberOfPeopleCurrentlyBenefittingFromCleanEnergyFinancing(sl, df) {
  return sl * (1 - df / 100);
}

function ImpactMetrics() {
  const [s1, setS1] = useState(0);
  const [s2, setS2] = useState(0);
  const [s3, setS3] = useState(0);
  const [s4, setS4] = useState(0);
  const [sl1, setSl1] = useState(0);
  const [sl2, setSl2] = useState(0);
  const [sl3, setSl3] = useState(0);
  const [sl4, setSl4] = useState(0);
  const [pl1, setPl1] = useState(0);
  const [pl2, setPl2] = useState(0);
  const [pl3, setPl3] = useState(0);
  const [pl4, setPl4] = useState(0);
  const [bf1, setBf1] = useState(0);
  const [bf2, setBf2] = useState(0);
  const [bf3, setBf3] = useState(0);
  const [bf4, setBf4] = useState(0);
  const [c1, setC1] = useState(0);
  const [c2, setC2] = useState(0);
  const [c3, setC3] = useState(0);
  const [c4, setC4] = useState(0);
  const [df1, setDf1] = useState(0);
  const [df2, setDf2] = useState(0);
  const [df3, setDf3] = useState(0);
  const [df4, setDf4] = useState(0);
  const [tco1, setTco1] = useState(0);
  const [tco2, setTco2] = useState(0);
  const [tco3, setTco3] = useState(0);
  const [tco4, setTco4] = useState(0);
  const [dl, setDl] = useState(3);
  const [dlFlag, setDlFlag] = useState(false);
  const [dr1, setDr1] = useState(10);
  const [drFlag1, setDrFlag1] = useState(false);
  const [dr2, setDr2] = useState(3);
  const [drFlag2, setDrFlag2] = useState(false);
  const [dr3, setDr3] = useState(3);
  const [drFlag3, setDrFlag3] = useState(false);
  const [dr4, setDr4] = useState(3);
  const [drFlag4, setDrFlag4] = useState(false);
  const [h, setH] = useState(5.5);
  const [hFlag, setHFlag] = useState(false);
  const [dt1, setDt1] = useState(1);
  const [dtFlag1, setDtFlag1] = useState(false);
  const [dt2, setDt2] = useState(1);
  const [dtFlag2, setDtFlag2] = useState(false);
  const [eaEastAfrica1, setEaEastAfrica1] = useState(14);
  const [eaEastAfricaFlag1, setEaEastAfricaFlag1] = useState(false);
  const [eaEastAfrica2, setEaEastAfrica2] = useState(29);
  const [eaEastAfricaFlag2, setEaEastAfricaFlag2] = useState(false);
  const [eaEastAfrica3, setEaEastAfrica3] = useState(23);
  const [eaEastAfricaFlag3, setEaEastAfricaFlag3] = useState(false);
  const [eaEastAfrica4, setEaEastAfrica4] = useState(23);
  const [eaEastAfricaFlag4, setEaEastAfricaFlag4] = useState(false);
  const [eaWestAfrica1, setEaWestAfrica1] = useState(14);
  const [eaWestAfricaFlag1, setEaWestAfricaFlag1] = useState(false);
  const [eaWestAfrica2, setEaWestAfrica2] = useState(18);
  const [eaWestAfricaFlag2, setEaWestAfricaFlag2] = useState(false);
  const [eaWestAfrica3, setEaWestAfrica3] = useState(17);
  const [eaWestAfricaFlag3, setEaWestAfricaFlag3] = useState(false);
  const [eaWestAfrica4, setEaWestAfrica4] = useState(11);
  const [eaWestAfricaFlag4, setEaWestAfricaFlag4] = useState(false);
  const [eaSouthAfrica1, setEaSouthAfrica1] = useState(14);
  const [eaSouthAfricaFlag1, setEaSouthAfricaFlag1] = useState(false);
  const [eaSouthAfrica2, setEaSouthAfrica2] = useState(10);
  const [eaSouthAfricaFlag2, setEaSouthAfricaFlag2] = useState(false);
  const [eaSouthAfrica3, setEaSouthAfrica3] = useState(8);
  const [eaSouthAfricaFlag3, setEaSouthAfricaFlag3] = useState(false);
  const [eaSouthAfrica4, setEaSouthAfrica4] = useState(11);
  const [eaSouthAfricaFlag4, setEaSouthAfricaFlag4] = useState(false);
  const [eaGlobalDefault1, setEaGlobalDefault1] = useState(14);
  const [eaGlobalDefaultFlag1, setEaGlobalDefaultFlag1] = useState(false);
  const [eaGlobalDefault2, setEaGlobalDefault2] = useState(10);
  const [eaGlobalDefaultFlag2, setEaGlobalDefaultFlag2] = useState(false);
  const [eaGlobalDefault3, setEaGlobalDefault3] = useState(8);
  const [eaGlobalDefaultFlag3, setEaGlobalDefaultFlag3] = useState(false);
  const [eaGlobalDefault4, setEaGlobalDefault4] = useState(11);
  const [eaGlobalDefaultFlag4, setEaGlobalDefaultFlag4] = useState(false);
  const [eEastAfrica1, setEEastAfrica1] = useState(10);
  const [eEastAfricaFlag1, setEEastAfricaFlag1] = useState(false);
  const [eEastAfrica2, setEEastAfrica2] = useState(16);
  const [eEastAfricaFlag2, setEEastAfricaFlag2] = useState(false);
  const [eEastAfrica3, setEEastAfrica3] = useState(14);
  const [eEastAfricaFlag3, setEEastAfricaFlag3] = useState(false);
  const [eEastAfrica4, setEEastAfrica4] = useState(10);
  const [eEastAfricaFlag4, setEEastAfricaFlag4] = useState(false);
  const [eWestAfrica1, setEWestAfrica1] = useState(10);
  const [eWestAfricaFlag1, setEWestAfricaFlag1] = useState(false);
  const [eWestAfrica2, setEWestAfrica2] = useState(7);
  const [eWestAfricaFlag2, setEWestAfricaFlag2] = useState(false);
  const [eWestAfrica3, setEWestAfrica3] = useState(12);
  const [eWestAfricaFlag3, setEWestAfricaFlag3] = useState(false);
  const [eWestAfrica4, setEWestAfrica4] = useState(9);
  const [eWestAfricaFlag4, setEWestAfricaFlag4] = useState(false);
  const [eSouthAfrica1, setESouthAfrica1] = useState(10);
  const [eSouthAfricaFlag1, setESouthAfricaFlag1] = useState(false);
  const [eSouthAfrica2, setESouthAfrica2] = useState(5);
  const [eSouthAfricaFlag2, setESouthAfricaFlag2] = useState(false);
  const [eSouthAfrica3, setESouthAfrica3] = useState(10);
  const [eSouthAfricaFlag3, setESouthAfricaFlag3] = useState(false);
  const [eSouthAfrica4, setESouthAfrica4] = useState(12);
  const [eSouthAfricaFlag4, setESouthAfricaFlag4] = useState(false);
  const [eGlobalDefault1, setEGlobalDefault1] = useState(10);
  const [eGlobalDefaultFlag1, setEGlobalDefaultFlag1] = useState(false);
  const [eGlobalDefault2, setEGlobalDefault2] = useState(5);
  const [eGlobalDefaultFlag2, setEGlobalDefaultFlag2] = useState(false);
  const [eGlobalDefault3, setEGlobalDefault3] = useState(10);
  const [eGlobalDefaultFlag3, setEGlobalDefaultFlag3] = useState(false);
  const [eGlobalDefault4, setEGlobalDefault4] = useState(12);
  const [eGlobalDefaultFlag4, setEGlobalDefaultFlag4] = useState(false);
  const [tEastAfrica1, setTEastAfrica1] = useState(5);
  const [tEastAfricaFlag1, setTEastAfricaFlag1] = useState(false);
  const [tEastAfrica2, setTEastAfrica2] = useState(16);
  const [tEastAfricaFlag2, setTEastAfricaFlag2] = useState(false);
  const [tEastAfrica3, setTEastAfrica3] = useState(13);
  const [tEastAfricaFlag3, setTEastAfricaFlag3] = useState(false);
  const [tEastAfrica4, setTEastAfrica4] = useState(11);
  const [tEastAfricaFlag4, setTEastAfricaFlag4] = useState(false);
  const [tWestAfrica1, setTWestAfrica1] = useState(5);
  const [tWestAfricaFlag1, setTWestAfricaFlag1] = useState(false);
  const [tWestAfrica2, setTWestAfrica2] = useState(7);
  const [tWestAfricaFlag2, setTWestAfricaFlag2] = useState(false);
  const [tWestAfrica3, setTWestAfrica3] = useState(7);
  const [tWestAfricaFlag3, setTWestAfricaFlag3] = useState(false);
  const [tWestAfrica4, setTWestAfrica4] = useState(5);
  const [tWestAfricaFlag4, setTWestAfricaFlag4] = useState(false);
  const [tSouthAfrica1, setTSouthAfrica1] = useState(5);
  const [tSouthAfricaFlag1, setTSouthAfricaFlag1] = useState(false);
  const [tSouthAfrica2, setTSouthAfrica2] = useState(6);
  const [tSouthAfricaFlag2, setTSouthAfricaFlag2] = useState(false);
  const [tSouthAfrica3, setTSouthAfrica3] = useState(6);
  const [tSouthAfricaFlag3, setTSouthAfricaFlag3] = useState(false);
  const [tSouthAfrica4, setTSouthAfrica4] = useState(8);
  const [tSouthAfricaFlag4, setTSouthAfricaFlag4] = useState(false);
  const [tGlobalDefault1, setTGlobalDefault1] = useState(5);
  const [tGlobalDefaultFlag1, setTGlobalDefaultFlag1] = useState(false);
  const [tGlobalDefault2, setTGlobalDefault2] = useState(6);
  const [tGlobalDefaultFlag2, setTGlobalDefaultFlag2] = useState(false);
  const [tGlobalDefault3, setTGlobalDefault3] = useState(6);
  const [tGlobalDefaultFlag3, setTGlobalDefaultFlag3] = useState(false);
  const [tGlobalDefault4, setTGlobalDefault4] = useState(5);
  const [tGlobalDefaultFlag4, setTGlobalDefaultFlag4] = useState(false);
  const [prEastAfrica1, setPrEastAfrica1] = useState(1);
  const [prEastAfricaFlag1, setPrEastAfricaFlag1] = useState(false);
  const [prEastAfrica2, setPrEastAfrica2] = useState(1.8);
  const [prEastAfricaFlag2, setPrEastAfricaFlag2] = useState(false);
  const [prEastAfrica3, setPrEastAfrica3] = useState(1.8);
  const [prEastAfricaFlag3, setPrEastAfricaFlag3] = useState(false);
  const [prEastAfrica4, setPrEastAfrica4] = useState(1.8);
  const [prEastAfricaFlag4, setPrEastAfricaFlag4] = useState(false);
  const [prWestAfrica1, setPrWestAfrica1] = useState(1);
  const [prWestAfricaFlag1, setPrWestAfricaFlag1] = useState(false);
  const [prWestAfrica2, setPrWestAfrica2] = useState(2.5);
  const [prWestAfricaFlag2, setPrWestAfricaFlag2] = useState(false);
  const [prWestAfrica3, setPrWestAfrica3] = useState(2.5);
  const [prWestAfricaFlag3, setPrWestAfricaFlag3] = useState(false);
  const [prWestAfrica4, setPrWestAfrica4] = useState(2.5);
  const [prWestAfricaFlag4, setPrWestAfricaFlag4] = useState(false);
  const [prSouthAfrica1, setPrSouthAfrica1] = useState(1);
  const [prSouthAfricaFlag1, setPrSouthAfricaFlag1] = useState(false);
  const [prSouthAfrica2, setPrSouthAfrica2] = useState(1.3);
  const [prSouthAfricaFlag2, setPrSouthAfricaFlag2] = useState(false);
  const [prSouthAfrica3, setPrSouthAfrica3] = useState(1.3);
  const [prSouthAfricaFlag3, setPrSouthAfricaFlag3] = useState(false);
  const [prSouthAfrica4, setPrSouthAfrica4] = useState(1.3);
  const [prSouthAfricaFlag4, setPrSouthAfricaFlag4] = useState(false);
  const [prGlobalDefault1, setPrGlobalDefault1] = useState(1);
  const [prGlobalDefaultFlag1, setPrGlobalDefaultFlag1] = useState(false);
  const [prGlobalDefault2, setPrGlobalDefault2] = useState(1.3);
  const [prGlobalDefaultFlag2, setPrGlobalDefaultFlag2] = useState(false);
  const [prGlobalDefault3, setPrGlobalDefault3] = useState(1.3);
  const [prGlobalDefaultFlag3, setPrGlobalDefaultFlag3] = useState(false);
  const [prGlobalDefault4, setPrGlobalDefault4] = useState(1.3);
  const [prGlobalDefaultFlag4, setPrGlobalDefaultFlag4] = useState(false);
  const [igEastAfrica1, setIgEastAfrica1] = useState(10);
  const [igEastAfricaFlag1, setIgEastAfricaFlag1] = useState(false);
  const [igEastAfrica2, setIgEastAfrica2] = useState(23);
  const [igEastAfricaFlag2, setIgEastAfricaFlag2] = useState(false);
  const [igEastAfrica3, setIgEastAfrica3] = useState(19);
  const [igEastAfricaFlag3, setIgEastAfricaFlag3] = useState(false);
  const [igEastAfrica4, setIgEastAfrica4] = useState(19);
  const [igEastAfricaFlag4, setIgEastAfricaFlag4] = useState(false);
  const [igWestAfrica1, setIgWestAfrica1] = useState(10);
  const [igWestAfricaFlag1, setIgWestAfricaFlag1] = useState(false);
  const [igWestAfrica2, setIgWestAfrica2] = useState(12);
  const [igWestAfricaFlag2, setIgWestAfricaFlag2] = useState(false);
  const [igWestAfrica3, setIgWestAfrica3] = useState(12);
  const [igWestAfricaFlag3, setIgWestAfricaFlag3] = useState(false);
  const [igWestAfrica4, setIgWestAfrica4] = useState(9);
  const [igWestAfricaFlag4, setIgWestAfricaFlag4] = useState(false);
  const [igSouthAfrica1, setIgSouthAfrica1] = useState(10);
  const [igSouthAfricaFlag1, setIgSouthAfricaFlag1] = useState(false);
  const [igSouthAfrica2, setIgSouthAfrica2] = useState(9);
  const [igSouthAfricaFlag2, setIgSouthAfricaFlag2] = useState(false);
  const [igSouthAfrica3, setIgSouthAfrica3] = useState(7);
  const [igSouthAfricaFlag3, setIgSouthAfricaFlag3] = useState(false);
  const [igSouthAfrica4, setIgSouthAfrica4] = useState(10);
  const [igSouthAfricaFlag4, setIgSouthAfricaFlag4] = useState(false);
  const [igGlobalDefault1, setIgGlobalDefault1] = useState(10);
  const [igGlobalDefaultFlag1, setIgGlobalDefaultFlag1] = useState(false);
  const [igGlobalDefault2, setIgGlobalDefault2] = useState(9);
  const [igGlobalDefaultFlag2, setIgGlobalDefaultFlag2] = useState(false);
  const [igGlobalDefault3, setIgGlobalDefault3] = useState(7);
  const [igGlobalDefaultFlag3, setIgGlobalDefaultFlag3] = useState(false);
  const [igGlobalDefault4, setIgGlobalDefault4] = useState(9);
  const [igGlobalDefaultFlag4, setIgGlobalDefaultFlag4] = useState(false);
  const [aiEastAfrica1, setAiEastAfrica1] = useState(170);
  const [aiEastAfricaFlag1, setAiEastAfricaFlag1] = useState(false);
  const [aiEastAfrica2, setAiEastAfrica2] = useState(306);
  const [aiEastAfricaFlag2, setAiEastAfricaFlag2] = useState(false);
  const [aiEastAfrica3, setAiEastAfrica3] = useState(429);
  const [aiEastAfricaFlag3, setAiEastAfricaFlag3] = useState(false);
  const [aiEastAfrica4, setAiEastAfrica4] = useState(475);
  const [aiEastAfricaFlag4, setAiEastAfricaFlag4] = useState(false);
  const [aiWestAfrica1, setAiWestAfrica1] = useState(170);
  const [aiWestAfricaFlag1, setAiWestAfricaFlag1] = useState(false);
  const [aiWestAfrica2, setAiWestAfrica2] = useState(263);
  const [aiWestAfricaFlag2, setAiWestAfricaFlag2] = useState(false);
  const [aiWestAfrica3, setAiWestAfrica3] = useState(392);
  const [aiWestAfricaFlag3, setAiWestAfricaFlag3] = useState(false);
  const [aiWestAfrica4, setAiWestAfrica4] = useState(149);
  const [aiWestAfricaFlag4, setAiWestAfricaFlag4] = useState(false);
  const [aiSouthAfrica1, setAiSouthAfrica1] = useState(170);
  const [aiSouthAfricaFlag1, setAiSouthAfricaFlag1] = useState(false);
  const [aiSouthAfrica2, setAiSouthAfrica2] = useState(548);
  const [aiSouthAfricaFlag2, setAiSouthAfricaFlag2] = useState(false);
  const [aiSouthAfrica3, setAiSouthAfrica3] = useState(548);
  const [aiSouthAfricaFlag3, setAiSouthAfricaFlag3] = useState(false);
  const [aiSouthAfrica4, setAiSouthAfrica4] = useState(548);
  const [aiSouthAfricaFlag4, setAiSouthAfricaFlag4] = useState(false);
  const [aiGlobalDefault1, setAiGlobalDefault1] = useState(170);
  const [aiGlobalDefaultFlag1, setAiGlobalDefaultFlag1] = useState(false);
  const [aiGlobalDefault2, setAiGlobalDefault2] = useState(263);
  const [aiGlobalDefaultFlag2, setAiGlobalDefaultFlag2] = useState(false);
  const [aiGlobalDefault3, setAiGlobalDefault3] = useState(392);
  const [aiGlobalDefaultFlag3, setAiGlobalDefaultFlag3] = useState(false);
  const [aiGlobalDefault4, setAiGlobalDefault4] = useState(149);
  const [aiGlobalDefaultFlag4, setAiGlobalDefaultFlag4] = useState(false);
  const [rEastAfrica1, setREastAfrica1] = useState(1);
  const [rEastAfricaFlag1, setREastAfricaFlag1] = useState(false);
  const [rEastAfrica2, setREastAfrica2] = useState(1.2);
  const [rEastAfricaFlag2, setREastAfricaFlag2] = useState(false);
  const [rEastAfrica3, setREastAfrica3] = useState(1.3);
  const [rEastAfricaFlag3, setREastAfricaFlag3] = useState(false);
  const [rEastAfrica4, setREastAfrica4] = useState(1.1);
  const [rEastAfricaFlag4, setREastAfricaFlag4] = useState(false);
  const [rWestAfrica1, setRWestAfrica1] = useState(1);
  const [rWestAfricaFlag1, setRWestAfricaFlag1] = useState(false);
  const [rWestAfrica2, setRWestAfrica2] = useState(0.2);
  const [rWestAfricaFlag2, setRWestAfricaFlag2] = useState(false);
  const [rWestAfrica3, setRWestAfrica3] = useState(0.2);
  const [rWestAfricaFlag3, setRWestAfricaFlag3] = useState(false);
  const [rWestAfrica4, setRWestAfrica4] = useState(0.4);
  const [rWestAfricaFlag4, setRWestAfricaFlag4] = useState(false);
  const [rSouthAfrica1, setRSouthAfrica1] = useState(1);
  const [rSouthAfricaFlag1, setRSouthAfricaFlag1] = useState(false);
  const [rSouthAfrica2, setRSouthAfrica2] = useState(0.9);
  const [rSouthAfricaFlag2, setRSouthAfricaFlag2] = useState(false);
  const [rSouthAfrica3, setRSouthAfrica3] = useState(0.2);
  const [rSouthAfricaFlag3, setRSouthAfricaFlag3] = useState(false);
  const [rSouthAfrica4, setRSouthAfrica4] = useState(0.3);
  const [rSouthAfricaFlag4, setRSouthAfricaFlag4] = useState(false);
  const [rGlobalDefault1, setRGlobalDefault1] = useState(1);
  const [rGlobalDefaultFlag1, setRGlobalDefaultFlag1] = useState(false);
  const [rGlobalDefault2, setRGlobalDefault2] = useState(0.4);
  const [rGlobalDefaultFlag2, setRGlobalDefaultFlag2] = useState(false);
  const [rGlobalDefault3, setRGlobalDefault3] = useState(0.4);
  const [rGlobalDefaultFlag3, setRGlobalDefaultFlag3] = useState(false);
  const [rGlobalDefault4, setRGlobalDefault4] = useState(0.4);
  const [rGlobalDefaultFlag4, setRGlobalDefaultFlag4] = useState(false);
  const [g, setG] = useState(0.431);
  const [gFlag, setGFlag] = useState(false);
  const [lb1, setLb1] = useState(5.8);
  const [lbFlag1, setLbFlag1] = useState(false);
  const [lb2, setLb2] = useState(3.9);
  const [lbFlag2, setLbFlag2] = useState(false);
  const [lb3, setLb3] = useState(4.1);
  const [lbFlag3, setLbFlag3] = useState(false);
  const [lb4, setLb4] = useState(3.8);
  const [lbFlag4, setLbFlag4] = useState(false);
  const [lf1, setLf1] = useState(8.1);
  const [lfFlag1, setLfFlag1] = useState(false);
  const [lf2, setLf2] = useState(5.3);
  const [lfFlag2, setLfFlag2] = useState(false);
  const [lf3, setLf3] = useState(5.4);
  const [lfFlag3, setLfFlag3] = useState(false);
  const [lf4, setLf4] = useState(5.7);
  const [lfFlag4, setLfFlag4] = useState(false);
  const [ld, setLd] = useState(350);
  const [ldFlag, setLdFlag] = useState(false);
  const [bb1, setBb1] = useState(35);
  const [bbFlag1, setBbFlag1] = useState(false);
  const [bb2, setBb2] = useState(45);
  const [bbFlag2, setBbFlag2] = useState(false);
  const [bb3, setBb3] = useState(45);
  const [bbFlag3, setBbFlag3] = useState(false);
  const [bb4, setBb4] = useState(45);
  const [bbFlag4, setBbFlag4] = useState(false);
  const [eb1, setEb1] = useState(95);
  const [ebFlag1, setEbFlag1] = useState(false);
  const [eb2, setEb2] = useState(127);
  const [ebFlag2, setEbFlag2] = useState(false);
  const [eb3, setEb3] = useState(127);
  const [ebFlag3, setEbFlag3] = useState(false);
  const [eb4, setEb4] = useState(127);
  const [ebFlag4, setEbFlag4] = useState(false);
  const [ef1, setEf1] = useState(22);
  const [efFlag1, setEfFlag1] = useState(false);
  const [ef2, setEf2] = useState(38);
  const [efFlag2, setEfFlag2] = useState(false);
  const [ef3, setEf3] = useState(38);
  const [efFlag3, setEfFlag3] = useState(false);
  const [ef4, setEf4] = useState(38);
  const [efFlag4, setEfFlag4] = useState(false);

  const [impactData, setImpactData] = useState({
    columns: [
      { Header: "Impact Metrics", accessor: "title", width: "60%" },
      { Header: "0.5 - 2.999 Wp", accessor: "swp", width: "10%" },
      { Header: "3 - 10.999 Wp", accessor: "mwp", width: "10%" },
      { Header: "11 - 49.999 Wp", accessor: "lwp", width: "10%" },
      { Header: "50+ Wp", accessor: "xlwp", width: "10%" },
    ],
    rows: [
      {
        title: "Number of people with improved energy access, cumulatively",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "Number of people with improved energy access, currently",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "Number of people with access to Tier 1 energy services",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "Number of people with access to Tier 2 energy services",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "People undertaking more economic activity",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "People using products to support enterprise",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "People that spend more time working",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "Households generating additional income",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "Additional income generated, cumulatively",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "Kerosene lanterns replaced",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "CO2e emissions avoided",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "Additional light hours used, by household",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "Additional light hours used, cumulatively",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "Change in quality of light, by household",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "Savings on energy expenditure for pico-solar, by household",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "Savings on energy expenditure for pico-solar, cumulatively",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
      {
        title: "Number of people currently benefitting from clean energy financing",
        swp: "",
        mwp: "",
        lwp: "",
        xlwp: "",
      },
    ],
  });

  const [selectedArea, setSelectedArea] = useState("East Africa");
  const caculateFunc = () => {
    let ea1;
    let ea2;
    let ea3;
    let ea4;
    let e1;
    let e2;
    let e3;
    let e4;
    let t1;
    let t2;
    let t3;
    let t4;
    let pr1;
    let pr2;
    let pr3;
    let pr4;
    let ig1;
    let ig2;
    let ig3;
    let ig4;
    let ai1;
    let ai2;
    let ai3;
    let ai4;
    let r1;
    let r2;
    let r3;
    let r4;
    if (selectedArea === "East Africa") {
      ea1 = eaEastAfrica1;
      ea2 = eaEastAfrica2;
      ea3 = eaEastAfrica3;
      ea4 = eaEastAfrica4;
      e1 = eEastAfrica1;
      e2 = eEastAfrica2;
      e3 = eEastAfrica3;
      e4 = eEastAfrica4;
      t1 = tEastAfrica1;
      t2 = tEastAfrica2;
      t3 = tEastAfrica3;
      t4 = tEastAfrica4;
      pr1 = prEastAfrica1;
      pr2 = prEastAfrica2;
      pr3 = prEastAfrica3;
      pr4 = prEastAfrica4;
      ig1 = igEastAfrica1;
      ig2 = igEastAfrica2;
      ig3 = igEastAfrica3;
      ig4 = igEastAfrica4;
      ai1 = aiEastAfrica1;
      ai2 = aiEastAfrica2;
      ai3 = aiEastAfrica3;
      ai4 = aiEastAfrica4;
      r1 = rEastAfrica1;
      r2 = rEastAfrica2;
      r3 = rEastAfrica3;
      r4 = rEastAfrica4;
    } else if (selectedArea === "West Africa") {
      ea1 = eaWestAfrica1;
      ea2 = eaWestAfrica2;
      ea3 = eaWestAfrica3;
      ea4 = eaWestAfrica4;
      e1 = eWestAfrica1;
      e2 = eWestAfrica2;
      e3 = eWestAfrica3;
      e4 = eWestAfrica4;
      t1 = tWestAfrica1;
      t2 = tWestAfrica2;
      t3 = tWestAfrica3;
      t4 = tWestAfrica4;
      pr1 = prWestAfrica1;
      pr2 = prWestAfrica2;
      pr3 = prWestAfrica3;
      pr4 = prWestAfrica4;
      ig1 = igWestAfrica1;
      ig2 = igWestAfrica2;
      ig3 = igWestAfrica3;
      ig4 = igWestAfrica4;
      ai1 = aiWestAfrica1;
      ai2 = aiWestAfrica2;
      ai3 = aiWestAfrica3;
      ai4 = aiWestAfrica4;
      r1 = rWestAfrica1;
      r2 = rWestAfrica2;
      r3 = rWestAfrica3;
      r4 = rWestAfrica4;
    } else if (selectedArea === "South Africa") {
      ea1 = eaSouthAfrica1;
      ea2 = eaSouthAfrica2;
      ea3 = eaSouthAfrica3;
      ea4 = eaSouthAfrica4;
      e1 = eSouthAfrica1;
      e2 = eSouthAfrica2;
      e3 = eSouthAfrica3;
      e4 = eSouthAfrica4;
      t1 = tSouthAfrica1;
      t2 = tSouthAfrica2;
      t3 = tSouthAfrica3;
      t4 = tSouthAfrica4;
      pr1 = prSouthAfrica1;
      pr2 = prSouthAfrica2;
      pr3 = prSouthAfrica3;
      pr4 = prSouthAfrica4;
      ig1 = igSouthAfrica1;
      ig2 = igSouthAfrica2;
      ig3 = igSouthAfrica3;
      ig4 = igSouthAfrica4;
      ai1 = aiSouthAfrica1;
      ai2 = aiSouthAfrica2;
      ai3 = aiSouthAfrica3;
      ai4 = aiSouthAfrica4;
      r1 = rSouthAfrica1;
      r2 = rSouthAfrica2;
      r3 = rSouthAfrica3;
      r4 = rSouthAfrica4;
    } else {
      ea1 = eaGlobalDefault1;
      ea2 = eaGlobalDefault2;
      ea3 = eaGlobalDefault3;
      ea4 = eaGlobalDefault4;
      e1 = eGlobalDefault1;
      e2 = eGlobalDefault2;
      e3 = eGlobalDefault3;
      e4 = eGlobalDefault4;
      t1 = tGlobalDefault1;
      t2 = tGlobalDefault2;
      t3 = tGlobalDefault3;
      t4 = tGlobalDefault4;
      pr1 = prGlobalDefault1;
      pr2 = prGlobalDefault2;
      pr3 = prGlobalDefault3;
      pr4 = prGlobalDefault4;
      ig1 = igGlobalDefault1;
      ig2 = igGlobalDefault2;
      ig3 = igGlobalDefault3;
      ig4 = igGlobalDefault4;
      ai1 = aiGlobalDefault1;
      ai2 = aiGlobalDefault2;
      ai3 = aiGlobalDefault3;
      ai4 = aiGlobalDefault4;
      r1 = rGlobalDefault1;
      r2 = rGlobalDefault2;
      r3 = rGlobalDefault3;
      r4 = rGlobalDefault4;
    }
    const dr = [dr1, dr2, dr3, dr4];
    const ea = [ea1, ea2, ea3, ea4];
    const e = [e1, e2, e3, e4];
    const t = [t1, t2, t3, t4];
    const pr = [pr1, pr2, pr3, pr4];
    const ig = [ig1, ig2, ig3, ig4];
    const ai = [ai1, ai2, ai3, ai4];
    const r = [r1, r2, r3, r4];
    const lb = [lb1, lb2, lb3, lb4];
    const lf = [lf1, lf2, lf3, lf4];
    const bb = [bb1, bb2, bb3, bb4];
    const eb = [eb1, eb2, eb3, eb4];
    const ef = [ef1, ef2, ef3, ef4];
    const s = [s1, s2, s3, s4];
    const sl = [sl1, sl2, sl3, sl4];
    const pl = [pl1, pl2, pl3, pl4];
    const bf = [bf1, bf2, bf3, bf4];
    const c = [c1, c2, c3, c4];
    const df = [df1, df2, df3, df4];
    const tco = [tco1, tco2, tco3, tco4];

    const impactResult = {
      columns: [
        { Header: "Impact Metrics", accessor: "title", width: "60%" },
        { Header: "0.5 - 2.999 Wp", accessor: "swp", width: "10%" },
        { Header: "3 - 10.999 Wp", accessor: "mwp", width: "10%" },
        { Header: "11 - 49.999 Wp", accessor: "lwp", width: "10%" },
        { Header: "50+ Wp", accessor: "xlwp", width: "10%" },
      ],
      rows: [],
    };
    let cash;
    let paygo;
    let res;
    let tempObject = {
      title: "Number of people with improved energy access, cumulatively",
    };
    const objProperties = ["swp", "mwp", "lwp", "xlwp"];
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = CumulativelyNumberOfPeopleWithImprovedEnergyAccess(s[i], dl, df[i], dr[i], h);
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "Number of people with improved energy access, currently",
    };
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = CurrentlyNumberOfPeopleWithImprovedEnergyAccess(sl[i], dl, df[i], dr[i], h);
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "Number of people with access to Tier 1 energy services",
    };
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = NumberOfPeopleWithAccessToTier1EnergyServices(
        sl[i],
        dl,
        df[i],
        dr[i],
        h,
        dt1
      );
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "Number of people with access to Tier 2 energy services",
    };
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = NumberOfPeopleWithAccessToTier2EnergyServices(
        sl[i],
        dl,
        df[i],
        dr[i],
        h,
        dt2
      );
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "People undertaking more economic activity",
    };
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = PeopleUndertakingMoreEconomicActivity(sl[i], dl, df[i], ea[i], pr[i]);
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "People using products to support enterprise",
    };
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = PeopleUsingProductsToSupportEnterprise(sl[i], dl, df[i], e[i]);
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "People that spend more time working",
    };
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = PeopleThatSpendMoreTimeWorking(sl[i], dl, df[i], t[i], pr[i]);
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "Households generating additional income",
    };
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = HouseholdsGeneratingAdditionalIncode(sl[i], dl, df[i], ig[i]);
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "Additional income generated, cumulatively",
    };
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = CumulativelyAdditionalIncomeGenerated(s[i], dl, df[i], ig[i], ai[i], pl[i]);
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "Kerosene lanterns replaced",
    };
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = KeroseneLaternsReplaced(sl[i], dl, df[i], r[i]);
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "CO2e emissions avoided",
    };
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = Co2EmissionsAvoided(s[i], dl, df[i], r[i], g, pl[i]);
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "Additional light hours used, by household",
    };
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = ByHouseholdAdditionalLightHoursUsed(lf[i], lb[i], ld, pl[i]);
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "Additional light hours used, cumulatively",
    };
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = CumulativelyAdditionalLightHoursUsed(
        s[i],
        dl,
        df[i],
        lf[i],
        lb[i],
        ld,
        pl[i]
      );
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "Change in quality of light, by household",
    };
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = ByHouseholdChangeInQualityOfLight(bf[i], bb[i]);
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "Savings on energy expenditure for pico-solar, by household",
    };
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = ByHouseholdSavingsOnEnergyExpenditureForPicoSolar(
        eb[i],
        ef[i],
        pl[i],
        c[i],
        tco[i]
      );
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "Savings on energy expenditure for pico-solar, cumulatively",
    };
    for (let i = 0; i < 4; i += 1) {
      [cash, paygo] = CumulativelySavingsOnEnergyExpenditureForPicoSolar(
        s[i],
        df[i],
        ef[i],
        eb[i],
        pl[i],
        c[i],
        tco[i]
      );
      res = (
        <div>
          Cash: {cash.toFixed(2)}
          <br />
          PAYGo: {paygo.toFixed(2)}
        </div>
      );
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);

    tempObject = {
      title: "Number of people currently benefitting from clean energy financing",
    };
    for (let i = 0; i < 4; i += 1) {
      paygo = NumberOfPeopleCurrentlyBenefittingFromCleanEnergyFinancing(sl[i], df[i]);
      res = <div>PAYGo: {paygo.toFixed(2)}</div>;
      tempObject[objProperties[i]] = res;
    }
    impactResult.rows.push(tempObject);
    setImpactData(impactResult);
  };

  const initializeFunc = () => {
    setDl(3);
    setDr1(10);
    setDr2(3);
    setDr3(3);
    setDr4(3);
    setH(5.5);
    setDt1(1);
    setDt2(1);
    setEaEastAfrica1(14);
    setEaEastAfrica2(29);
    setEaEastAfrica3(23);
    setEaEastAfrica4(23);
    setEaWestAfrica1(14);
    setEaWestAfrica2(18);
    setEaWestAfrica3(17);
    setEaWestAfrica4(11);
    setEaSouthAfrica1(14);
    setEaSouthAfrica2(10);
    setEaSouthAfrica3(8);
    setEaSouthAfrica4(11);
    setEaGlobalDefault1(14);
    setEaGlobalDefault2(10);
    setEaGlobalDefault3(8);
    setEaGlobalDefault4(11);
    setEEastAfrica1(10);
    setEEastAfrica2(16);
    setEEastAfrica3(14);
    setEEastAfrica4(10);
    setEWestAfrica1(10);
    setEWestAfrica2(7);
    setEWestAfrica3(12);
    setEWestAfrica4(9);
    setESouthAfrica1(10);
    setESouthAfrica2(5);
    setESouthAfrica3(10);
    setESouthAfrica4(12);
    setEGlobalDefault1(10);
    setEGlobalDefault2(5);
    setEGlobalDefault3(10);
    setEGlobalDefault4(12);
    setTEastAfrica1(5);
    setTEastAfrica2(16);
    setTEastAfrica3(13);
    setTEastAfrica4(11);
    setTWestAfrica1(5);
    setTWestAfrica2(7);
    setTWestAfrica3(7);
    setTWestAfrica4(5);
    setTSouthAfrica1(5);
    setTSouthAfrica2(6);
    setTSouthAfrica3(6);
    setTSouthAfrica4(8);
    setTGlobalDefault1(5);
    setTGlobalDefault2(6);
    setTGlobalDefault3(6);
    setTGlobalDefault4(5);
    setPrEastAfrica1(1);
    setPrEastAfrica2(1.8);
    setPrEastAfrica3(1.8);
    setPrEastAfrica4(1.8);
    setPrWestAfrica1(1);
    setPrWestAfrica2(2.5);
    setPrWestAfrica3(2.5);
    setPrWestAfrica4(2.5);
    setPrSouthAfrica1(1);
    setPrSouthAfrica2(1.3);
    setPrSouthAfrica3(1.3);
    setPrSouthAfrica4(1.3);
    setPrGlobalDefault1(1);
    setPrGlobalDefault2(1.3);
    setPrGlobalDefault3(1.3);
    setPrGlobalDefault4(1.3);
    setIgEastAfrica1(10);
    setIgEastAfrica2(23);
    setIgEastAfrica3(19);
    setIgEastAfrica4(19);
    setIgWestAfrica1(10);
    setIgWestAfrica2(12);
    setIgWestAfrica3(12);
    setIgWestAfrica4(9);
    setIgSouthAfrica1(10);
    setIgSouthAfrica2(9);
    setIgSouthAfrica3(7);
    setIgSouthAfrica4(10);
    setIgGlobalDefault1(10);
    setIgGlobalDefault2(9);
    setIgGlobalDefault3(7);
    setIgGlobalDefault4(9);
    setAiEastAfrica1(170);
    setAiEastAfrica2(306);
    setAiEastAfrica3(429);
    setAiEastAfrica4(475);
    setAiWestAfrica1(170);
    setAiWestAfrica2(263);
    setAiWestAfrica3(392);
    setAiWestAfrica4(149);
    setAiSouthAfrica1(170);
    setAiSouthAfrica2(548);
    setAiSouthAfrica3(548);
    setAiSouthAfrica4(548);
    setAiGlobalDefault1(170);
    setAiGlobalDefault2(263);
    setAiGlobalDefault3(392);
    setAiGlobalDefault4(149);
    setREastAfrica1(1);
    setREastAfrica2(1.2);
    setREastAfrica3(1.3);
    setREastAfrica4(1.1);
    setRWestAfrica1(1);
    setRWestAfrica2(0.2);
    setRWestAfrica3(0.2);
    setRWestAfrica4(0.4);
    setRSouthAfrica1(1);
    setRSouthAfrica2(0.9);
    setRSouthAfrica3(0.2);
    setRSouthAfrica4(0.3);
    setRGlobalDefault1(1);
    setRGlobalDefault2(0.4);
    setRGlobalDefault3(0.4);
    setRGlobalDefault4(0.4);
    setG(0.431);
    setLb1(5.8);
    setLb2(3.9);
    setLb3(4.1);
    setLb4(3.8);
    setLf1(8.1);
    setLf2(5.3);
    setLf3(5.4);
    setLf4(5.7);
    setLd(350);
    setBb1(35);
    setBb2(45);
    setBb3(45);
    setBb4(45);
    setEb1(95);
    setEb2(127);
    setEb3(127);
    setEb4(127);
    setEf1(22);
    setEf2(38);
    setEf3(38);
    setEf4(38);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <br />
        <Grid container alignItems="center" spacing={5}>
          <Grid item xs={4} md={4} lg={4}>
            <MDBox mb={1}>
              <Autocomplete
                defaultValue="Gogla"
                options={["Gogla"]}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </MDBox>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <MDBox mb={1}>
              <Autocomplete
                defaultValue="East Africa"
                options={["East Africa", "West Africa", "South Africa", "Global Default"]}
                onChange={(event, newValue) => {
                  setSelectedArea(newValue);
                }}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </MDBox>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <MDBox mb={1}>
              <MDButton variant="gradient" color="dark" onClick={initializeFunc}>
                initialization
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5">Variable (input by users)</MDTypography>
              </MDBox>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Variable (input by users)</TableCell>
                      <TableCell align="center">0.5 - 2.999 Wp</TableCell>
                      <TableCell align="center">3 - 10.999 Wp</TableCell>
                      <TableCell align="center">11 - 49.999 Wp</TableCell>
                      <TableCell align="center">50+ Wp</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>S:</i>
                        </b>
                        <br />
                        Number of units sold (cumulative i.e. ever)
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={s1}
                          onChange={(event) => setS1(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={s2}
                          onChange={(event) => setS2(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={s3}
                          onChange={(event) => setS3(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={s4}
                          onChange={(event) => setS4(Number(event.target.value))}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>
                            S
                            <small>
                              <small>L</small>
                            </small>
                            :
                          </i>
                        </b>
                        <br />
                        Number of units sold within estimated lifespan of product (1.5 x warranty
                        period)
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={sl1}
                          onChange={(event) => setSl1(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={sl2}
                          onChange={(event) => setSl2(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={sl3}
                          onChange={(event) => setSl3(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={sl4}
                          onChange={(event) => setSl4(Number(event.target.value))}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>
                            P
                            <small>
                              <small>L</small>
                            </small>
                            :
                          </i>
                        </b>
                        <br />
                        Estimated solar product lifespan (1.5 x warranty)
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={pl1}
                          onChange={(event) => setPl1(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={pl2}
                          onChange={(event) => setPl2(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={pl3}
                          onChange={(event) => setPl3(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={pl4}
                          onChange={(event) => setPl4(Number(event.target.value))}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>
                            B
                            <small>
                              <small>F</small>
                            </small>
                            :
                          </i>
                        </b>
                        <br />
                        Average post-purchase lumens (brightness) of household lighting
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={bf1}
                          onChange={(event) => setBf1(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={bf2}
                          onChange={(event) => setBf2(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={bf3}
                          onChange={(event) => setBf3(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={bf4}
                          onChange={(event) => setBf4(Number(event.target.value))}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>C:</i>
                        </b>
                        <br />
                        Average retail price of solar product (cost to customer), in US$ (Cash only)
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={c1}
                          onChange={(event) => setC1(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={c2}
                          onChange={(event) => setC2(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={c3}
                          onChange={(event) => setC3(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={c4}
                          onChange={(event) => setC4(Number(event.target.value))}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>
                            D
                            <small>
                              <small>F</small>
                            </small>
                            :
                          </i>
                        </b>
                        <br />
                        Discount for loss factor: products not used for full lifetime (PAYGo only)
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={df1}
                          onChange={(event) => setDf1(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={df2}
                          onChange={(event) => setDf2(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={df3}
                          onChange={(event) => setDf3(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={df4}
                          onChange={(event) => setDf4(Number(event.target.value))}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>TCO:</i>
                        </b>
                        <br />
                        Average total cost of ownership of solar product (cost to customer), in US$
                        (PAYGo only)
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={tco1}
                          onChange={(event) => setTco1(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={tco2}
                          onChange={(event) => setTco2(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={tco3}
                          onChange={(event) => setTco3(Number(event.target.value))}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FormField
                          label=""
                          type="text"
                          defaultValue={tco4}
                          onChange={(event) => setTco4(Number(event.target.value))}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5">Variable (default values)</MDTypography>
              </MDBox>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Variable (default values)</TableCell>
                      <TableCell align="center">0.5 - 2.999 Wp</TableCell>
                      <TableCell align="center">3 - 10.999 Wp</TableCell>
                      <TableCell align="center">11 - 49.999 Wp</TableCell>
                      <TableCell align="center">50+ Wp</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>
                            D
                            <small>
                              <small>L</small>
                            </small>
                            :
                          </i>
                        </b>
                        <br />
                        Discount for loss: products not working or not in use, excluding loss in
                        supply chain (Cash only)
                      </TableCell>
                      <TableCell colSpan={4} align="center">
                        {dlFlag === false ? (
                          <Button onClick={() => setDlFlag(true)} color="dark" variant="text">
                            {dl}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={dl}
                            onChange={(event) => setDl(Number(event.target.value))}
                            onBlur={() => setDlFlag(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>
                            D
                            <small>
                              <small>R</small>
                            </small>
                            :
                          </i>
                        </b>
                        <br />
                        Discount for repeat sales: to avoid double counting of customers, but does
                        not try to estimate proportion of customers who owned solar more generally
                        before
                      </TableCell>
                      <TableCell align="center">
                        {drFlag1 === false ? (
                          <Button onClick={() => setDrFlag1(true)} color="dark" variant="text">
                            {dr1}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={dr1}
                            onChange={(event) => setDr1(Number(event.target.value))}
                            onBlur={() => setDrFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {drFlag2 === false ? (
                          <Button onClick={() => setDrFlag2(true)} color="dark" variant="text">
                            {dr2}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={dr2}
                            onChange={(event) => setDr2(Number(event.target.value))}
                            onBlur={() => setDrFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {drFlag3 === false ? (
                          <Button onClick={() => setDrFlag3(true)} color="dark" variant="text">
                            {dr3}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={dr3}
                            onChange={(event) => setDr3(Number(event.target.value))}
                            onBlur={() => setDrFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {drFlag4 === false ? (
                          <Button onClick={() => setDrFlag4(true)} color="dark" variant="text">
                            {dr4}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={dr4}
                            onChange={(event) => setDr4(Number(event.target.value))}
                            onBlur={() => setDrFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>H:</i>
                        </b>
                        <br />
                        Household size
                      </TableCell>
                      <TableCell colSpan={4} align="center">
                        {hFlag === false ? (
                          <Button onClick={() => setHFlag(true)} color="dark" variant="text">
                            {h}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={h}
                            onChange={(event) => setH(Number(event.target.value))}
                            onBlur={() => setHFlag(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>
                            D
                            <small>
                              <small>T1</small>
                            </small>
                            :
                          </i>
                        </b>
                        <br />
                        Tier 1 Factor
                      </TableCell>
                      <TableCell colSpan={4} align="center">
                        {dtFlag1 === false ? (
                          <Button onClick={() => setDtFlag1(true)} color="dark" variant="text">
                            {dt1}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={dt1}
                            onChange={(event) => setDt1(Number(event.target.value))}
                            onBlur={() => setDtFlag1(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>
                            D
                            <small>
                              <small>T2</small>
                            </small>
                            :
                          </i>
                        </b>
                        <br />
                        Tier 2 Factor
                      </TableCell>
                      <TableCell colSpan={4} align="center">
                        {dtFlag2 === false ? (
                          <Button onClick={() => setDtFlag2(true)} color="dark" variant="text">
                            {dt2}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={dt2}
                            onChange={(event) => setDt2(Number(event.target.value))}
                            onBlur={() => setDtFlag2(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell rowSpan={4}>
                        <b>
                          <i>EA:</i>
                        </b>
                        <br />
                        Percentage of customers undertaking more economic activity
                      </TableCell>
                      <TableCell align="center">
                        {eaEastAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setEaEastAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaEastAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaEastAfrica1}
                            onChange={(event) => setEaEastAfrica1(Number(event.target.value))}
                            onBlur={() => setEaEastAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eaEastAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setEaEastAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaEastAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaEastAfrica2}
                            onChange={(event) => setEaEastAfrica2(Number(event.target.value))}
                            onBlur={() => setEaEastAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eaEastAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setEaEastAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaEastAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaEastAfrica3}
                            onChange={(event) => setEaEastAfrica3(Number(event.target.value))}
                            onBlur={() => setEaEastAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eaEastAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setEaEastAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaEastAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaEastAfrica4}
                            onChange={(event) => setEaEastAfrica4(Number(event.target.value))}
                            onBlur={() => setEaEastAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {eaWestAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setEaWestAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaWestAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaWestAfrica1}
                            onChange={(event) => setEaWestAfrica1(Number(event.target.value))}
                            onBlur={() => setEaWestAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eaWestAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setEaWestAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaWestAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaWestAfrica2}
                            onChange={(event) => setEaWestAfrica2(Number(event.target.value))}
                            onBlur={() => setEaWestAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eaWestAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setEaWestAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaWestAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaWestAfrica3}
                            onChange={(event) => setEaWestAfrica3(Number(event.target.value))}
                            onBlur={() => setEaWestAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eaWestAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setEaWestAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaWestAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaWestAfrica4}
                            onChange={(event) => setEaWestAfrica4(Number(event.target.value))}
                            onBlur={() => setEaWestAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {eaSouthAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setEaSouthAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaSouthAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaSouthAfrica1}
                            onChange={(event) => setEaSouthAfrica1(Number(event.target.value))}
                            onBlur={() => setEaSouthAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eaSouthAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setEaSouthAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaSouthAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaSouthAfrica2}
                            onChange={(event) => setEaSouthAfrica2(Number(event.target.value))}
                            onBlur={() => setEaSouthAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eaSouthAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setEaSouthAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaSouthAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaSouthAfrica3}
                            onChange={(event) => setEaSouthAfrica3(Number(event.target.value))}
                            onBlur={() => setEaSouthAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eaSouthAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setEaSouthAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaSouthAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaSouthAfrica4}
                            onChange={(event) => setEaSouthAfrica4(Number(event.target.value))}
                            onBlur={() => setEaSouthAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {eaGlobalDefaultFlag1 === false ? (
                          <Button
                            onClick={() => setEaGlobalDefaultFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaGlobalDefault1}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaGlobalDefault1}
                            onChange={(event) => setEaGlobalDefault1(Number(event.target.value))}
                            onBlur={() => setEaGlobalDefaultFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eaGlobalDefaultFlag2 === false ? (
                          <Button
                            onClick={() => setEaGlobalDefaultFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaGlobalDefault2}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaGlobalDefault2}
                            onChange={(event) => setEaGlobalDefault2(Number(event.target.value))}
                            onBlur={() => setEaGlobalDefaultFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eaGlobalDefaultFlag3 === false ? (
                          <Button
                            onClick={() => setEaGlobalDefaultFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaGlobalDefault3}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaGlobalDefault3}
                            onChange={(event) => setEaGlobalDefault3(Number(event.target.value))}
                            onBlur={() => setEaGlobalDefaultFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eaGlobalDefaultFlag4 === false ? (
                          <Button
                            onClick={() => setEaGlobalDefaultFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {eaGlobalDefault4}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eaGlobalDefault4}
                            onChange={(event) => setEaGlobalDefault4(Number(event.target.value))}
                            onBlur={() => setEaGlobalDefaultFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell rowSpan={4}>
                        <b>
                          <i>E:</i>
                        </b>
                        <br />
                        Percentage of customers using products to support enterprise (including
                        those that have opened a new business)
                      </TableCell>
                      <TableCell align="center">
                        {eEastAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setEEastAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {eEastAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eEastAfrica1}
                            onChange={(event) => setEEastAfrica1(Number(event.target.value))}
                            onBlur={() => setEEastAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eEastAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setEEastAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {eEastAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eEastAfrica2}
                            onChange={(event) => setEEastAfrica2(Number(event.target.value))}
                            onBlur={() => setEEastAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eEastAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setEEastAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {eEastAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eEastAfrica3}
                            onChange={(event) => setEEastAfrica3(Number(event.target.value))}
                            onBlur={() => setEEastAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eEastAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setEEastAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {eEastAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eEastAfrica4}
                            onChange={(event) => setEEastAfrica4(Number(event.target.value))}
                            onBlur={() => setEEastAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {eWestAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setEWestAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {eWestAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eWestAfrica1}
                            onChange={(event) => setEWestAfrica1(Number(event.target.value))}
                            onBlur={() => setEWestAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eWestAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setEWestAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {eWestAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eWestAfrica2}
                            onChange={(event) => setEWestAfrica2(Number(event.target.value))}
                            onBlur={() => setEWestAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eWestAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setEWestAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {eWestAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eWestAfrica3}
                            onChange={(event) => setEWestAfrica3(Number(event.target.value))}
                            onBlur={() => setEWestAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eWestAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setEWestAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {eWestAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eWestAfrica4}
                            onChange={(event) => setEWestAfrica4(Number(event.target.value))}
                            onBlur={() => setEWestAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {eSouthAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setESouthAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {eSouthAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eSouthAfrica1}
                            onChange={(event) => setESouthAfrica1(Number(event.target.value))}
                            onBlur={() => setESouthAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eSouthAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setESouthAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {eSouthAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eSouthAfrica2}
                            onChange={(event) => setESouthAfrica2(Number(event.target.value))}
                            onBlur={() => setESouthAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eSouthAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setESouthAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {eSouthAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eSouthAfrica3}
                            onChange={(event) => setESouthAfrica3(Number(event.target.value))}
                            onBlur={() => setESouthAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eSouthAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setESouthAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {eSouthAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eSouthAfrica4}
                            onChange={(event) => setESouthAfrica4(Number(event.target.value))}
                            onBlur={() => setESouthAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {eGlobalDefaultFlag1 === false ? (
                          <Button
                            onClick={() => setEGlobalDefaultFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {eGlobalDefault1}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eGlobalDefault1}
                            onChange={(event) => setEGlobalDefault1(Number(event.target.value))}
                            onBlur={() => setEGlobalDefaultFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eGlobalDefaultFlag2 === false ? (
                          <Button
                            onClick={() => setEGlobalDefaultFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {eGlobalDefault2}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eGlobalDefault2}
                            onChange={(event) => setEGlobalDefault2(Number(event.target.value))}
                            onBlur={() => setEGlobalDefaultFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eGlobalDefaultFlag3 === false ? (
                          <Button
                            onClick={() => setEGlobalDefaultFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {eGlobalDefault3}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eGlobalDefault3}
                            onChange={(event) => setEGlobalDefault3(Number(event.target.value))}
                            onBlur={() => setEGlobalDefaultFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {eGlobalDefaultFlag4 === false ? (
                          <Button
                            onClick={() => setEGlobalDefaultFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {eGlobalDefault4}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={eGlobalDefault4}
                            onChange={(event) => setEGlobalDefault4(Number(event.target.value))}
                            onBlur={() => setEGlobalDefaultFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell rowSpan={4}>
                        <b>
                          <i>T:</i>
                        </b>
                        <br />
                        Percentage of customers that spend more time working
                      </TableCell>
                      <TableCell align="center">
                        {tEastAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setTEastAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {tEastAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={tEastAfrica1}
                            onChange={(event) => setTEastAfrica1(Number(event.target.value))}
                            onBlur={() => setTEastAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tEastAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setTEastAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {tEastAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={tEastAfrica2}
                            onChange={(event) => setTEastAfrica2(Number(event.target.value))}
                            onBlur={() => setTEastAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tEastAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setTEastAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {tEastAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={tEastAfrica3}
                            onChange={(event) => setTEastAfrica3(Number(event.target.value))}
                            onBlur={() => setTEastAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tEastAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setTEastAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {tEastAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            label=""
                            type="text"
                            defaultValue={tEastAfrica4}
                            onChange={(event) => setTEastAfrica4(Number(event.target.value))}
                            onBlur={() => setTEastAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {tWestAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setTWestAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {tWestAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={tWestAfrica1}
                            onChange={(event) => setTWestAfrica1(Number(event.target.value))}
                            onBlur={() => setTWestAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tWestAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setTWestAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {tWestAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={tWestAfrica2}
                            onChange={(event) => setTWestAfrica2(Number(event.target.value))}
                            onBlur={() => setTWestAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tWestAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setTWestAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {tWestAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={tWestAfrica3}
                            onChange={(event) => setTWestAfrica3(Number(event.target.value))}
                            onBlur={() => setTWestAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tWestAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setTWestAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {tWestAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={tWestAfrica4}
                            onChange={(event) => setTWestAfrica4(Number(event.target.value))}
                            onBlur={() => setTWestAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {tSouthAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setTSouthAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {tSouthAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={tSouthAfrica1}
                            onChange={(event) => setTSouthAfrica1(Number(event.target.value))}
                            onBlur={() => setTSouthAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tSouthAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setTSouthAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {tSouthAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={tSouthAfrica2}
                            onChange={(event) => setTSouthAfrica2(Number(event.target.value))}
                            onBlur={() => setTSouthAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tSouthAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setTSouthAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {tSouthAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={tSouthAfrica3}
                            onChange={(event) => setTSouthAfrica3(Number(event.target.value))}
                            onBlur={() => setTSouthAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tSouthAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setTSouthAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {tSouthAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={tSouthAfrica4}
                            onChange={(event) => setTSouthAfrica4(Number(event.target.value))}
                            onBlur={() => setTSouthAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {tGlobalDefaultFlag1 === false ? (
                          <Button
                            onClick={() => setTGlobalDefaultFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {tGlobalDefault1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={tGlobalDefault1}
                            onChange={(event) => setTGlobalDefault1(Number(event.target.value))}
                            onBlur={() => setTGlobalDefaultFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tGlobalDefaultFlag2 === false ? (
                          <Button
                            onClick={() => setTGlobalDefaultFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {tGlobalDefault2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={tGlobalDefault2}
                            onChange={(event) => setTGlobalDefault2(Number(event.target.value))}
                            onBlur={() => setTGlobalDefaultFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tGlobalDefaultFlag3 === false ? (
                          <Button
                            onClick={() => setTGlobalDefaultFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {tGlobalDefault3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={tGlobalDefault3}
                            onChange={(event) => setTGlobalDefault3(Number(event.target.value))}
                            onBlur={() => setTGlobalDefaultFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tGlobalDefaultFlag4 === false ? (
                          <Button
                            onClick={() => setTGlobalDefaultFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {tGlobalDefault4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={tGlobalDefault4}
                            onChange={(event) => setTGlobalDefault4(Number(event.target.value))}
                            onBlur={() => setTGlobalDefaultFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell rowSpan={4}>
                        <b>
                          <i>PR:</i>
                        </b>
                        <br />
                        Ratio for the number of people per household
                      </TableCell>
                      <TableCell align="center">
                        {prEastAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setPrEastAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {prEastAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prEastAfrica1}
                            onChange={(event) => setPrEastAfrica1(Number(event.target.value))}
                            onBlur={() => setPrEastAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {prEastAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setPrEastAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {prEastAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prEastAfrica2}
                            onChange={(event) => setPrEastAfrica2(Number(event.target.value))}
                            onBlur={() => setPrEastAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {prEastAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setPrEastAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {prEastAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prEastAfrica3}
                            onChange={(event) => setPrEastAfrica3(Number(event.target.value))}
                            onBlur={() => setPrEastAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {prEastAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setPrEastAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {prEastAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prEastAfrica4}
                            onChange={(event) => setPrEastAfrica4(Number(event.target.value))}
                            onBlur={() => setPrEastAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {prWestAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setPrWestAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {prWestAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prWestAfrica1}
                            onChange={(event) => setPrWestAfrica1(Number(event.target.value))}
                            onBlur={() => setPrWestAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {prWestAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setPrWestAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {prWestAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prWestAfrica2}
                            onChange={(event) => setPrWestAfrica2(Number(event.target.value))}
                            onBlur={() => setPrWestAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {prWestAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setPrWestAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {prWestAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prWestAfrica3}
                            onChange={(event) => setPrWestAfrica3(Number(event.target.value))}
                            onBlur={() => setPrWestAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {prWestAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setPrWestAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {prWestAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prWestAfrica4}
                            onChange={(event) => setPrWestAfrica4(Number(event.target.value))}
                            onBlur={() => setPrWestAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {prSouthAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setPrSouthAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {prSouthAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prSouthAfrica1}
                            onChange={(event) => setPrSouthAfrica1(Number(event.target.value))}
                            onBlur={() => setPrSouthAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {prSouthAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setPrSouthAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {prSouthAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prSouthAfrica2}
                            onChange={(event) => setPrSouthAfrica2(Number(event.target.value))}
                            onBlur={() => setPrSouthAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {prSouthAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setPrSouthAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {prSouthAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prSouthAfrica3}
                            onChange={(event) => setPrSouthAfrica3(Number(event.target.value))}
                            onBlur={() => setPrSouthAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {prSouthAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setPrSouthAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {prSouthAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prSouthAfrica4}
                            onChange={(event) => setPrSouthAfrica4(Number(event.target.value))}
                            onBlur={() => setPrSouthAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {prGlobalDefaultFlag1 === false ? (
                          <Button
                            onClick={() => setPrGlobalDefaultFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {prGlobalDefault1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prGlobalDefault1}
                            onChange={(event) => setPrGlobalDefault1(Number(event.target.value))}
                            onBlur={() => setPrGlobalDefaultFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {prGlobalDefaultFlag2 === false ? (
                          <Button
                            onClick={() => setPrGlobalDefaultFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {prGlobalDefault2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prGlobalDefault2}
                            onChange={(event) => setPrGlobalDefault2(Number(event.target.value))}
                            onBlur={() => setPrGlobalDefaultFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {prGlobalDefaultFlag3 === false ? (
                          <Button
                            onClick={() => setPrGlobalDefaultFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {prGlobalDefault3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prGlobalDefault3}
                            onChange={(event) => setPrGlobalDefault3(Number(event.target.value))}
                            onBlur={() => setPrGlobalDefaultFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {prGlobalDefaultFlag4 === false ? (
                          <Button
                            onClick={() => setPrGlobalDefaultFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {prGlobalDefault4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={prGlobalDefault4}
                            onChange={(event) => setPrGlobalDefault4(Number(event.target.value))}
                            onBlur={() => setPrGlobalDefaultFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell rowSpan={4}>
                        <b>
                          <i>IG:</i>
                        </b>
                        <br />
                        Percentage of households creating additional income
                      </TableCell>
                      <TableCell align="center">
                        {igEastAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setIgEastAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {igEastAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igEastAfrica1}
                            onChange={(event) => setIgEastAfrica1(Number(event.target.value))}
                            onBlur={() => setIgEastAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {igEastAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setIgEastAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {igEastAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igEastAfrica2}
                            onChange={(event) => setIgEastAfrica2(Number(event.target.value))}
                            onBlur={() => setIgEastAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {igEastAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setIgEastAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {igEastAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igEastAfrica3}
                            onChange={(event) => setIgEastAfrica3(Number(event.target.value))}
                            onBlur={() => setIgEastAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {igEastAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setIgEastAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {igEastAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igEastAfrica4}
                            onChange={(event) => setIgEastAfrica4(Number(event.target.value))}
                            onBlur={() => setIgEastAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {igWestAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setIgWestAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {igWestAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igWestAfrica1}
                            onChange={(event) => setIgWestAfrica1(Number(event.target.value))}
                            onBlur={() => setIgWestAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {igWestAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setIgWestAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {igWestAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igWestAfrica2}
                            onChange={(event) => setIgWestAfrica2(Number(event.target.value))}
                            onBlur={() => setIgWestAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {igWestAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setIgWestAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {igWestAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igWestAfrica3}
                            onChange={(event) => setIgWestAfrica3(Number(event.target.value))}
                            onBlur={() => setIgWestAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {igWestAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setIgWestAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {igWestAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igWestAfrica4}
                            onChange={(event) => setIgWestAfrica4(Number(event.target.value))}
                            onBlur={() => setIgWestAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {igSouthAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setIgSouthAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {igSouthAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igSouthAfrica1}
                            onChange={(event) => setIgSouthAfrica1(Number(event.target.value))}
                            onBlur={() => setIgSouthAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {igSouthAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setIgSouthAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {igSouthAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igSouthAfrica2}
                            onChange={(event) => setIgSouthAfrica2(Number(event.target.value))}
                            onBlur={() => setIgSouthAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {igSouthAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setIgSouthAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {igSouthAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igSouthAfrica3}
                            onChange={(event) => setIgSouthAfrica3(Number(event.target.value))}
                            onBlur={() => setIgSouthAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {igSouthAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setIgSouthAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {igSouthAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igSouthAfrica4}
                            onChange={(event) => setIgSouthAfrica4(Number(event.target.value))}
                            onBlur={() => setIgSouthAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {igGlobalDefaultFlag1 === false ? (
                          <Button
                            onClick={() => setIgGlobalDefaultFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {igGlobalDefault1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igGlobalDefault1}
                            onChange={(event) => setIgGlobalDefault1(Number(event.target.value))}
                            onBlur={() => setIgGlobalDefaultFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {igGlobalDefaultFlag2 === false ? (
                          <Button
                            onClick={() => setIgGlobalDefaultFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {igGlobalDefault2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igGlobalDefault2}
                            onChange={(event) => setIgGlobalDefault2(Number(event.target.value))}
                            onBlur={() => setIgGlobalDefaultFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {igGlobalDefaultFlag3 === false ? (
                          <Button
                            onClick={() => setIgGlobalDefaultFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {igGlobalDefault3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igGlobalDefault3}
                            onChange={(event) => setIgGlobalDefault3(Number(event.target.value))}
                            onBlur={() => setIgGlobalDefaultFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {igGlobalDefaultFlag4 === false ? (
                          <Button
                            onClick={() => setIgGlobalDefaultFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {igGlobalDefault4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={igGlobalDefault4}
                            onChange={(event) => setIgGlobalDefault4(Number(event.target.value))}
                            onBlur={() => setIgGlobalDefaultFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell rowSpan={4}>
                        <b>
                          <i>AI:</i>
                        </b>
                        <br />
                        Average additional income generated, per household (annual)
                      </TableCell>
                      <TableCell align="center">
                        {aiEastAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setAiEastAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiEastAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiEastAfrica1}
                            onChange={(event) => setAiEastAfrica1(Number(event.target.value))}
                            onBlur={() => setAiEastAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {aiEastAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setAiEastAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiEastAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiEastAfrica2}
                            onChange={(event) => setAiEastAfrica2(Number(event.target.value))}
                            onBlur={() => setAiEastAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {aiEastAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setAiEastAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiEastAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiEastAfrica3}
                            onChange={(event) => setAiEastAfrica3(Number(event.target.value))}
                            onBlur={() => setAiEastAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {aiEastAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setAiEastAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiEastAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiEastAfrica4}
                            onChange={(event) => setAiEastAfrica4(Number(event.target.value))}
                            onBlur={() => setAiEastAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {aiWestAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setAiWestAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiWestAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiWestAfrica1}
                            onChange={(event) => setAiWestAfrica1(Number(event.target.value))}
                            onBlur={() => setAiWestAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {aiWestAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setAiWestAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiWestAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiWestAfrica2}
                            onChange={(event) => setAiWestAfrica2(Number(event.target.value))}
                            onBlur={() => setAiWestAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {aiWestAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setAiWestAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiWestAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiWestAfrica3}
                            onChange={(event) => setAiWestAfrica3(Number(event.target.value))}
                            onBlur={() => setAiWestAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {aiWestAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setAiWestAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiWestAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiWestAfrica4}
                            onChange={(event) => setAiWestAfrica4(Number(event.target.value))}
                            onBlur={() => setAiWestAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {aiSouthAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setAiSouthAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiSouthAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiSouthAfrica1}
                            onChange={(event) => setAiSouthAfrica1(Number(event.target.value))}
                            onBlur={() => setAiSouthAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {aiSouthAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setAiSouthAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiSouthAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiSouthAfrica2}
                            onChange={(event) => setAiSouthAfrica2(Number(event.target.value))}
                            onBlur={() => setAiSouthAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {aiSouthAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setAiSouthAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiSouthAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiSouthAfrica3}
                            onChange={(event) => setAiSouthAfrica3(Number(event.target.value))}
                            onBlur={() => setAiSouthAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {aiSouthAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setAiSouthAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiSouthAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiSouthAfrica4}
                            onChange={(event) => setAiSouthAfrica4(Number(event.target.value))}
                            onBlur={() => setAiSouthAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {aiGlobalDefaultFlag1 === false ? (
                          <Button
                            onClick={() => setAiGlobalDefaultFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiGlobalDefault1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiGlobalDefault1}
                            onChange={(event) => setAiGlobalDefault1(Number(event.target.value))}
                            onBlur={() => setAiGlobalDefaultFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {aiGlobalDefaultFlag2 === false ? (
                          <Button
                            onClick={() => setAiGlobalDefaultFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiGlobalDefault2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiGlobalDefault2}
                            onChange={(event) => setAiGlobalDefault2(Number(event.target.value))}
                            onBlur={() => setAiGlobalDefaultFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {aiGlobalDefaultFlag3 === false ? (
                          <Button
                            onClick={() => setAiGlobalDefaultFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiGlobalDefault3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiGlobalDefault3}
                            onChange={(event) => setAiGlobalDefault3(Number(event.target.value))}
                            onBlur={() => setAiGlobalDefaultFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {aiGlobalDefaultFlag4 === false ? (
                          <Button
                            onClick={() => setAiGlobalDefaultFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {aiGlobalDefault4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={aiGlobalDefault4}
                            onChange={(event) => setAiGlobalDefault4(Number(event.target.value))}
                            onBlur={() => setAiGlobalDefaultFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell rowSpan={4}>
                        <b>
                          <i>R:</i>
                        </b>
                        <br />
                        Replacement ratio of kerosene lanterns per solar product
                      </TableCell>
                      <TableCell align="center">
                        {rEastAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setREastAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {rEastAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rEastAfrica1}
                            onChange={(event) => setREastAfrica1(Number(event.target.value))}
                            onBlur={() => setREastAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {rEastAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setREastAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {rEastAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rEastAfrica2}
                            onChange={(event) => setREastAfrica2(Number(event.target.value))}
                            onBlur={() => setREastAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {rEastAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setREastAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {rEastAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rEastAfrica3}
                            onChange={(event) => setREastAfrica3(Number(event.target.value))}
                            onBlur={() => setREastAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {rEastAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setREastAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {rEastAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rEastAfrica4}
                            onChange={(event) => setREastAfrica4(Number(event.target.value))}
                            onBlur={() => setREastAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {rWestAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setRWestAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {rWestAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rWestAfrica1}
                            onChange={(event) => setRWestAfrica1(Number(event.target.value))}
                            onBlur={() => setRWestAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {rWestAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setRWestAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {rWestAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rWestAfrica2}
                            onChange={(event) => setRWestAfrica2(Number(event.target.value))}
                            onBlur={() => setRWestAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {rWestAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setRWestAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {rWestAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rWestAfrica3}
                            onChange={(event) => setRWestAfrica3(Number(event.target.value))}
                            onBlur={() => setRWestAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {rWestAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setRWestAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {rWestAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rWestAfrica4}
                            onChange={(event) => setRWestAfrica4(Number(event.target.value))}
                            onBlur={() => setRWestAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {rSouthAfricaFlag1 === false ? (
                          <Button
                            onClick={() => setRSouthAfricaFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {rSouthAfrica1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rSouthAfrica1}
                            onChange={(event) => setRSouthAfrica1(Number(event.target.value))}
                            onBlur={() => setRSouthAfricaFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {rSouthAfricaFlag2 === false ? (
                          <Button
                            onClick={() => setRSouthAfricaFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {rSouthAfrica2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rSouthAfrica2}
                            onChange={(event) => setRSouthAfrica2(Number(event.target.value))}
                            onBlur={() => setRSouthAfricaFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {rSouthAfricaFlag3 === false ? (
                          <Button
                            onClick={() => setRSouthAfricaFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {rSouthAfrica3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rSouthAfrica3}
                            onChange={(event) => setRSouthAfrica3(Number(event.target.value))}
                            onBlur={() => setRSouthAfricaFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {rSouthAfricaFlag4 === false ? (
                          <Button
                            onClick={() => setRSouthAfricaFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {rSouthAfrica4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rSouthAfrica4}
                            onChange={(event) => setRSouthAfrica4(Number(event.target.value))}
                            onBlur={() => setRSouthAfricaFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        {rGlobalDefaultFlag1 === false ? (
                          <Button
                            onClick={() => setRGlobalDefaultFlag1(true)}
                            color="dark"
                            variant="text"
                          >
                            {rGlobalDefault1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rGlobalDefault1}
                            onChange={(event) => setRGlobalDefault1(Number(event.target.value))}
                            onBlur={() => setRGlobalDefaultFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {rGlobalDefaultFlag2 === false ? (
                          <Button
                            onClick={() => setRGlobalDefaultFlag2(true)}
                            color="dark"
                            variant="text"
                          >
                            {rGlobalDefault2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rGlobalDefault2}
                            onChange={(event) => setRGlobalDefault2(Number(event.target.value))}
                            onBlur={() => setRGlobalDefaultFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {rGlobalDefaultFlag3 === false ? (
                          <Button
                            onClick={() => setRGlobalDefaultFlag3(true)}
                            color="dark"
                            variant="text"
                          >
                            {rGlobalDefault3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rGlobalDefault3}
                            onChange={(event) => setRGlobalDefault3(Number(event.target.value))}
                            onBlur={() => setRGlobalDefaultFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {rGlobalDefaultFlag4 === false ? (
                          <Button
                            onClick={() => setRGlobalDefaultFlag4(true)}
                            color="dark"
                            variant="text"
                          >
                            {rGlobalDefault4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={rGlobalDefault4}
                            onChange={(event) => setRGlobalDefault4(Number(event.target.value))}
                            onBlur={() => setRGlobalDefaultFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>G:</i>
                        </b>
                        <br />
                        Average annual carbon dioxide and black carbon (CO2e) emissions per kerosene
                        lantern, in metric tons
                      </TableCell>
                      <TableCell colSpan={4} align="center">
                        {gFlag === false ? (
                          <Button onClick={() => setGFlag(true)} color="dark" variant="text">
                            {g}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={g}
                            onChange={(event) => setG(Number(event.target.value))}
                            onBlur={() => setGFlag(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>
                            L
                            <small>
                              <small>B</small>
                            </small>
                            :
                          </i>
                        </b>
                        <br />
                        Average baseline hours of light used, per day / night (24 hours) per
                        household
                      </TableCell>
                      <TableCell align="center">
                        {lbFlag1 === false ? (
                          <Button onClick={() => setLbFlag1(true)} color="dark" variant="text">
                            {lb1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={lb1}
                            onChange={(event) => setLb1(Number(event.target.value))}
                            onBlur={() => setLbFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {lbFlag2 === false ? (
                          <Button onClick={() => setLbFlag2(true)} color="dark" variant="text">
                            {lb2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={lb2}
                            onChange={(event) => setLb2(Number(event.target.value))}
                            onBlur={() => setLbFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {lbFlag3 === false ? (
                          <Button onClick={() => setLbFlag3(true)} color="dark" variant="text">
                            {lb3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={lb3}
                            onChange={(event) => setLb3(Number(event.target.value))}
                            onBlur={() => setLbFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {lbFlag4 === false ? (
                          <Button onClick={() => setLbFlag4(true)} color="dark" variant="text">
                            {lb4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={lb4}
                            onChange={(event) => setLb4(Number(event.target.value))}
                            onBlur={() => setLbFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>
                            L
                            <small>
                              <small>F</small>
                            </small>
                            :
                          </i>
                        </b>
                        <br />
                        Average post-purchase hours of light used, per day / night (24 hours), per
                        household
                      </TableCell>
                      <TableCell align="center">
                        {lfFlag1 === false ? (
                          <Button onClick={() => setLfFlag1(true)} color="dark" variant="text">
                            {lf1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={lf1}
                            onChange={(event) => setLf1(Number(event.target.value))}
                            onBlur={() => setLfFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {lfFlag2 === false ? (
                          <Button onClick={() => setLfFlag2(true)} color="dark" variant="text">
                            {lf2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={lf2}
                            onChange={(event) => setLf2(Number(event.target.value))}
                            onBlur={() => setLfFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {lfFlag3 === false ? (
                          <Button onClick={() => setLfFlag3(true)} color="dark" variant="text">
                            {lf3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={lf3}
                            onChange={(event) => setLf3(Number(event.target.value))}
                            onBlur={() => setLfFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {lfFlag4 === false ? (
                          <Button onClick={() => setLfFlag4(true)} color="dark" variant="text">
                            {lf4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={lf4}
                            onChange={(event) => setLf4(Number(event.target.value))}
                            onBlur={() => setLfFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>
                            L
                            <small>
                              <small>D</small>
                            </small>
                            :
                          </i>
                        </b>
                        <br />
                        Average number of days per year that off-grid solar product 350 is used for
                        lighting
                      </TableCell>
                      <TableCell colSpan={4} align="center">
                        {ldFlag === false ? (
                          <Button onClick={() => setLdFlag(true)} color="dark" variant="text">
                            {ld}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={ld}
                            onChange={(event) => setLd(Number(event.target.value))}
                            onBlur={() => setLdFlag(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>
                            B
                            <small>
                              <small>B</small>
                            </small>
                            :
                          </i>
                        </b>
                        <br />
                        Average baseline lumens (brightness) of household lighting use
                      </TableCell>
                      <TableCell align="center">
                        {bbFlag1 === false ? (
                          <Button onClick={() => setBbFlag1(true)} color="dark" variant="text">
                            {bb1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={bb1}
                            onChange={(event) => setBb1(Number(event.target.value))}
                            onBlur={() => setBbFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {bbFlag2 === false ? (
                          <Button onClick={() => setBbFlag2(true)} color="dark" variant="text">
                            {bb2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={bb2}
                            onChange={(event) => setBb2(Number(event.target.value))}
                            onBlur={() => setBbFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {bbFlag3 === false ? (
                          <Button onClick={() => setBbFlag3(true)} color="dark" variant="text">
                            {bb3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={bb3}
                            onChange={(event) => setBb3(Number(event.target.value))}
                            onBlur={() => setBbFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {bbFlag4 === false ? (
                          <Button onClick={() => setBbFlag4(true)} color="dark" variant="text">
                            {bb4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={bb4}
                            onChange={(event) => setBb4(Number(event.target.value))}
                            onBlur={() => setBbFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>
                            E
                            <small>
                              <small>B</small>
                            </small>
                            :
                          </i>
                        </b>
                        <br />
                        Average annual expenditure on energy baseline (lighting and phone charging),
                        per household
                      </TableCell>
                      <TableCell align="center">
                        {ebFlag1 === false ? (
                          <Button onClick={() => setEbFlag1(true)} color="dark" variant="text">
                            {eb1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={eb1}
                            onChange={(event) => setEb1(Number(event.target.value))}
                            onBlur={() => setEbFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {ebFlag2 === false ? (
                          <Button onClick={() => setEbFlag2(true)} color="dark" variant="text">
                            {eb2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={eb2}
                            onChange={(event) => setEb2(Number(event.target.value))}
                            onBlur={() => setEbFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {ebFlag3 === false ? (
                          <Button onClick={() => setEbFlag3(true)} color="dark" variant="text">
                            {eb3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={eb3}
                            onChange={(event) => setEb3(Number(event.target.value))}
                            onBlur={() => setEbFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {ebFlag4 === false ? (
                          <Button onClick={() => setEbFlag4(true)} color="dark" variant="text">
                            {eb4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={eb4}
                            onChange={(event) => setEb4(Number(event.target.value))}
                            onBlur={() => setEbFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>
                          <i>
                            E
                            <small>
                              <small>F</small>
                            </small>
                            :
                          </i>
                        </b>
                        <br />
                        Average annual expenditure on energy post-purchase (lighting and phone
                        charging), per household
                      </TableCell>
                      <TableCell align="center">
                        {efFlag1 === false ? (
                          <Button onClick={() => setEfFlag1(true)} color="dark" variant="text">
                            {ef1}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={ef1}
                            onChange={(event) => setEf1(Number(event.target.value))}
                            onBlur={() => setEfFlag1(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {efFlag2 === false ? (
                          <Button onClick={() => setEfFlag2(true)} color="dark" variant="text">
                            {ef2}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={ef2}
                            onChange={(event) => setEf2(Number(event.target.value))}
                            onBlur={() => setEfFlag2(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {efFlag3 === false ? (
                          <Button onClick={() => setEfFlag3(true)} color="dark" variant="text">
                            {ef3}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={ef3}
                            onChange={(event) => setEf3(Number(event.target.value))}
                            onBlur={() => setEfFlag3(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {efFlag4 === false ? (
                          <Button onClick={() => setEfFlag4(true)} color="dark" variant="text">
                            {ef4}
                          </Button>
                        ) : (
                          <FormField
                            type="text"
                            defaultValue={ef4}
                            onChange={(event) => setEf4(Number(event.target.value))}
                            onBlur={() => setEfFlag4(false)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
        <br />
        <Grid container alignItems="right" spacing={5}>
          <Grid item xs={4} md={4} lg={4}>
            <MDBox mb={1}> </MDBox>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <MDBox mb={1}> </MDBox>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <MDBox mb={1}>
              <MDButton variant="gradient" color="dark" onClick={caculateFunc}>
                Caculate
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <br />
        <Grid container alignItems="center">
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5">Impact Metrics (Cash/PAYGo)</MDTypography>
              </MDBox>
              <DataTable
                table={impactData}
                entriesPerPage={false}
                showTotalEntries={false}
                isSorted={false}
                noEndBorder
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ImpactMetrics;
