/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";

import { serverBaseURL } from "common/settings";
import { getToken } from "common/utils";

function Published() {
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "Select to Unpublish", accessor: "select" },
      { Header: "Site Name", accessor: "name" },
      { Header: "Current PV (kW)", accessor: "power" },
      { Header: "Best / Worst", accessor: "score" },
      { Header: "Problems", accessor: "problems" },
      { Header: "Grade", accessor: "grade" },
      { Header: "Category", accessor: "category" },
    ],

    rows: [],
  });
  const [selectedSite, setSelectedSite] = useState([]);
  const token = getToken();

  const selectSite = (i) => {
    const item = {
      ...selectedSite[i],
    };
    if (item) {
      item.selected = !item.selected;
      const temp = Array.from(selectedSite);
      temp[i] = item;
      setSelectedSite(temp);
    }
  };

  async function fetchData() {
    const response = await (
      await fetch(`${serverBaseURL}published_sites`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ).json();

    setSelectedSite(
      response.published_sites.map((site) => ({
        ...site,
        selected: false,
      }))
    );
  }

  const unpublish = async () => {
    let unpublishSites = selectedSite.filter((item) => item.selected);
    unpublishSites = unpublishSites.map((item) => item.name);
    const unpublishSiteNames = unpublishSites.join(":");
    const formData = new FormData();
    formData.append("unpublish_site_names", unpublishSiteNames);
    await (
      await fetch(`${serverBaseURL}unpublish`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
    ).json();

    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setTableData({
      columns: [
        { Header: "Select to Unpublish", accessor: "select" },
        { Header: "Site Name", accessor: "name" },
        { Header: "Current PV (kW)", accessor: "power" },
        { Header: "Best / Worst", accessor: "score" },
        { Header: "Problems", accessor: "problems" },
        { Header: "Grade", accessor: "grade" },
        { Header: "Category", accessor: "category" },
      ],

      rows: selectedSite.map((prop, key) => ({
        id: key,
        select: <Switch checked={prop.selected} onClick={() => selectSite(key)} />,
        name: prop.name,
        power: prop.pv,
        score: prop.score,
        problems: prop.problems,
        grade: prop.grade,
        category: prop.category,
      })),
    });
  }, [selectedSite]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <MDBox mt={5}>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="success"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  assignment
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Published Sites Table
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <DataTable table={tableData} canSearch />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <br />
        </MDBox>
        <Grid container alignItems="right" spacing={5}>
          <Grid item xs={4} md={4} lg={4}>
            <MDBox mb={1}> </MDBox>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <MDBox mb={1}> </MDBox>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <MDBox mb={1}>
              <MDButton variant="gradient" color="dark" onClick={unpublish}>
                Unpublish
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Published;
