/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";

import { serverBaseURL } from "common/settings";
import { getToken } from "common/utils";

function PredictiveAnalysis() {
  const navigate = useNavigate();
  const [statusData, setStatusData] = useState({
    columns: [
      { Header: "No.", accessor: "number" },
      { Header: "Date", accessor: "date" },
      { Header: "Red", accessor: "red" },
      { Header: "Yellow", accessor: "yellow" },
      { Header: "Green", accessor: "green" },
      { Header: "Total(%)", accessor: "total" },
      { Header: "Action", accessor: "actions" },
    ],

    rows: [],
  });
  const [forecastingData, setForecastingData] = useState({
    columns: [
      { Header: "Date", accessor: "date" },
      { Header: "Site Name", accessor: "name" },
      { Header: "Site Status", accessor: "status" },
      { Header: "Current PV (kW)", accessor: "power" },
      { Header: "Location", accessor: "location" },
      { Header: "Action", accessor: "actions" },
    ],

    rows: [],
  });
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [processTime, setProcessTime] = useState("");
  const [status, setStatus] = useState("");
  const token = getToken();

  useEffect(() => {
    async function fetchData() {
      const response = await (
        await fetch(`${serverBaseURL}get_forecast_result`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      ).json();

      setStatusData({
        columns: [
          { Header: "No.", accessor: "number" },
          { Header: "Date", accessor: "date" },
          { Header: "Red", accessor: "red" },
          { Header: "Yellow", accessor: "yellow" },
          { Header: "Green", accessor: "green" },
          { Header: "Total(%)", accessor: "total" },
          { Header: "Action", accessor: "actions" },
        ],

        rows: response.statistics.map((prop) => ({
          number: prop[0],
          date: prop[1],
          red: prop[2],
          yellow: prop[3],
          green: prop[4],
          total: `${((prop[4] / (prop[4] + prop[3] + prop[2])) * 100).toFixed(2)}%`,
          actions: (
            <div className="actions-right">
              <MDButton
                onClick={() => {
                  const sites = response.all_predict.filter((arr) => arr[0] === prop[1]);
                  const markers = sites.map((site) => {
                    const [lat, lng] = site[5].split(",");
                    return [site[2], lat, lng];
                  });
                  navigate("/dashboards/map", { state: { markerData: markers } });
                }}
              >
                <Icon fontSize="medium" color="primary">
                  map
                </Icon>
              </MDButton>
            </div>
          ),
        })),
      });

      setForecastingData({
        columns: [
          { Header: "Date", accessor: "date" },
          { Header: "Site Name", accessor: "name" },
          { Header: "Site Status", accessor: "status" },
          { Header: "Current PV (kW)", accessor: "power" },
          { Header: "Location", accessor: "location" },
          { Header: "Action", accessor: "actions" },
        ],

        rows: response.all_predict.map((prop, key) => ({
          id: key,
          date: prop[0],
          name: prop[1],
          status: prop[2],
          power: prop[3],
          area: prop[4],
          actions: (
            <div className="actions-right">
              <MDButton
                onClick={() => {
                  const [lat, lng] = prop[5].split(",");
                  const markers = [];
                  markers.push([prop[2], lat, lng]);
                  navigate("/dashboards/map", { state: { markerData: markers } });
                }}
              >
                <Icon fontSize="medium" color="primary">
                  map
                </Icon>
              </MDButton>
            </div>
          ),
        })),
      });

      console.log(response);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchTaskStatus() {
      const statusResponse = await (
        await fetch(`${serverBaseURL}caculate_status`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      ).json();
      console.log(statusResponse);
      setStatus(statusResponse.task_status);
      setStartTime(statusResponse.start_time);
      setEndTime(statusResponse.end_time);
      setProcessTime(statusResponse.process_time);
    }
    fetchTaskStatus();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <br />
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">Latest Task</MDTypography>
              <MDTypography variant="h6">Status: {status}</MDTypography>
              <MDTypography variant="h6">startTime: {startTime}</MDTypography>
              <MDTypography variant="h6">endTime: {endTime}</MDTypography>
              <MDTypography variant="h6">processTime: {processTime}</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">System Forecasting - By Country</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={5}>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="success"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  assignment
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Site Status Table
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <DataTable table={statusData} canSearch />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <br />
        </MDBox>
        <MDBox mt={5}>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="success"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  assignment
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Site Forecasting Table
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <DataTable table={forecastingData} canSearch />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <br />
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PredictiveAnalysis;
