import { useState, useMemo } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDropzone from "components/MDDropzone";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";

import { serverBaseURL } from "common/settings";

function Regression() {
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "Area", accessor: "area" },
      { Header: "Panel Output Predict", accessor: "output" },
    ],

    rows: [],
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <br />
        <Grid container alignItems="center" spacing={5}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox>
              <MDTypography variant="h5">Regression Upload</MDTypography>
              <MDBox mt={3}>
                <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Regression Upload
                  </MDTypography>
                </MDBox>
                {useMemo(
                  () => (
                    <MDDropzone
                      options={{
                        url: `${serverBaseURL}regression`,
                        XMLHttpRequest: true,
                        addRemoveLinks: true,
                        headers: {
                          "Cache-Control": null,
                          "X-Requested-With": null,
                        },
                        success(file, response) {
                          const responseObj = JSON.parse(response);
                          setTableData({
                            columns: [
                              { Header: "Area", accessor: "area" },
                              { Header: "Panel Output Predict", accessor: "output" },
                            ],

                            rows: responseObj.res.map((prop, key) => ({
                              id: key,
                              area: prop[0],
                              output: prop[1],
                              lat: prop[2],
                              lon: prop[3],
                            })),
                          });
                        },
                      }}
                      autoProcessQueue={false}
                    />
                  ),
                  []
                )}
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={5}>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="success"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  assignment
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Regression Table
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <DataTable table={tableData} canSearch />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <br />
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Regression;
