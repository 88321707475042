/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import Footer from "examples/Footer";

import { serverBaseURL } from "common/settings";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

function Overview() {
  const [trendOpportunityLoss, setTrendOpportunityLoss] = useState("");
  const [trendBatteryLoss80, setTrendBatteryLoss80] = useState("");
  const [trendBatteryLoss20, setTrendBatteryLoss20] = useState("");
  const [trendOptimizePanelLossRed, setTrendOptimizePanelLossRed] = useState("");
  const [trendOptimizePanelLossYellow, setTrendOptimizePanelLossYellow] = useState("");
  const [trendOptimizePanelLossTotal, setTrendOptimizePanelLossTotal] = useState("");
  const [trendImpactOfAlternativeEnergy, setTrendImpactOfAlternativeEnergy] = useState("");
  const [trendPowerGenerationDailyMax, setTrendPowerGenerationDailyMax] = useState("");
  const [trendPowerGenerationMonthlyMax, setTrendPowerGenerationMonthlyMax] = useState("");
  const [trendPowerGenerationDailyAverage, setTrendPowerGenerationDailyAverage] = useState("");
  const [trendPowerGenerationMonthlyAverage, setTrendPowerGenerationMonthlyAverage] = useState("");

  const [forecastOpportunityLoss, setForecastOpportunityLoss] = useState("");
  const [forecastBatteryLoss80, setForecastBatteryLoss80] = useState("");
  const [forecastBatteryLoss20, setForecastBatteryLoss20] = useState("");
  const [forecastPowerGenerationDailyMax, setForecastPowerGenerationDailyMax] = useState("");
  const [forecastPowerGenerationDailyAverage, setForecastPowerGenerationDailyAverage] =
    useState("");
  const [trendGraphData, setTrendGraphData] = useState({});

  const [forecastGraphData, setForecastGraphData] = useState({});

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [processTime, setProcessTime] = useState("");
  const [status, setStatus] = useState("");
  const { getToken } = useKindeAuth();

  useEffect(() => {
    async function fetchData() {
      const accessToken = await getToken();
      const response = await (
        await fetch(`${serverBaseURL}get_overview`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      ).json();

      const { forecast, trend } = response;

      const trendOpportunityLossNumber = Number(trend.opportunity_loss).toFixed(2);
      const trendOpportunityLossHourNumber = (Number(trend.opportunity_loss_time) / 3600).toFixed(
        2
      );
      setTrendOpportunityLoss(
        `${trendOpportunityLossNumber} kwh / ${trendOpportunityLossHourNumber} h`
      );

      const trendBatteryLoss80Number = Number(trend.battery_loss80).toFixed(2);
      const trendBatteryLoss80HourNumber = (Number(trend.battery_loss80_time) / 3600).toFixed(2);
      setTrendBatteryLoss80(`${trendBatteryLoss80Number} kwh / ${trendBatteryLoss80HourNumber} h`);

      const trendBatteryLoss20Number = Number(trend.battery_loss20).toFixed(2);
      const trendBatteryLoss20HourNumber = (Number(trend.battery_loss20_time) / 3600).toFixed(2);
      setTrendBatteryLoss20(`${trendBatteryLoss20Number} kwh / ${trendBatteryLoss20HourNumber} h`);

      const trendOptimizePanelLossRedNumber = Number(trend.optimize_panel_loss_red).toFixed(2);
      setTrendOptimizePanelLossRed(`${trendOptimizePanelLossRedNumber} kwh`);

      const trendOptimizePanelLossYellowNumber = Number(trend.optimize_panel_loss_yellow).toFixed(
        2
      );
      setTrendOptimizePanelLossYellow(`${trendOptimizePanelLossYellowNumber} kwh`);

      const trendOptimizePanelLossTotalNumber = Number(trend.optimize_panel_loss_total).toFixed(2);
      setTrendOptimizePanelLossTotal(`${trendOptimizePanelLossTotalNumber} kwh`);

      const trendImpactOfAlternativeEnergyNumber = (
        Number(trend.optimize_panel_loss_red) * 0.518
      ).toFixed(2);
      setTrendImpactOfAlternativeEnergy(`${trendImpactOfAlternativeEnergyNumber} kg-CO2`);

      const trendPowerGenerationDailyMaxNumber = Number(trend.daily_max).toFixed(2);
      const trendPowerGenerationDailyMaxDate = trend.max_date;
      setTrendPowerGenerationDailyMax(
        `${trendPowerGenerationDailyMaxNumber} kwh --- ${trendPowerGenerationDailyMaxDate}`
      );

      const trendPowerGenerationMonthlyMaxNumber = Number(trend.monthly_max).toFixed(2);
      const trendPowerGenerationMonthlyMaxMonth = trend.max_month;
      setTrendPowerGenerationMonthlyMax(
        `${trendPowerGenerationMonthlyMaxNumber} kwh --- ${trendPowerGenerationMonthlyMaxMonth}`
      );

      const trendPowerGenerationDailyAverageNumber = Number(trend.daily_mean).toFixed(2);
      setTrendPowerGenerationDailyAverage(`${trendPowerGenerationDailyAverageNumber} kwh`);

      const trendPowerGenerationMonthlyAverageNumber = Number(trend.monthly_mean).toFixed(2);
      setTrendPowerGenerationMonthlyAverage(`${trendPowerGenerationMonthlyAverageNumber} kwh`);

      const forecastOpportunityLossNumber = Number(forecast.total_opportunity_loss).toFixed(2);
      const forecastOpportunityLossHourNumber = forecast.total_opportunity_loss_hour;
      setForecastOpportunityLoss(
        `${forecastOpportunityLossNumber} kwh / ${forecastOpportunityLossHourNumber} h`
      );

      const forecastBatteryLoss80Number = Number(forecast.total_battery_loss80).toFixed(2);
      const forecastBatteryLoss80HourNumber = forecast.total_battery_loss80_hour;
      setForecastBatteryLoss80(
        `${forecastBatteryLoss80Number} kwh / ${forecastBatteryLoss80HourNumber} h`
      );

      const forecastBatteryLoss20Number = Number(forecast.total_battery_loss20).toFixed(2);
      const forecastBatteryLoss20HourNumber = forecast.total_battery_loss20_hour;
      setForecastBatteryLoss20(
        `${forecastBatteryLoss20Number} kwh / ${forecastBatteryLoss20HourNumber} h`
      );

      const forecastPowerGenerationDailyMaxNumber = Number(forecast.daily_max).toFixed(2);
      const forecastPowerGenerationDailyMaxDate = forecast.max_date;
      setForecastPowerGenerationDailyMax(
        `${forecastPowerGenerationDailyMaxNumber} kwh --- ${forecastPowerGenerationDailyMaxDate}`
      );

      const forecastPowerGenerationDailyAverageNumber = Number(forecast.daily_mean).toFixed(2);
      setForecastPowerGenerationDailyAverage(`${forecastPowerGenerationDailyAverageNumber} kwh`);

      setTrendGraphData({
        labels: trend.daily_generated_power.labels,
        datasets: [
          {
            label: "Power Generation",
            color: "success",
            data: trend.daily_generated_power.series[0],
          },
          {
            label: "Opportunity Loss",
            color: "info",
            data: trend.daily_opportunity_loss.series[0],
          },
          {
            label: "Battery Loss (Battery > 80%)",
            color: "warning",
            data: trend.daily_batteryloss80.series[0],
          },
          {
            label: "Battery Loss (Battery < 20%)",
            color: "error",
            data: trend.daily_batteryloss20.series[0],
          },
        ],
      });

      setForecastGraphData({
        labels: forecast.daily_generated_power.labels,
        datasets: [
          {
            label: "Power Generation",
            color: "success",
            data: forecast.daily_generated_power.series[0],
          },
          {
            label: "Opportunity Loss",
            color: "info",
            data: forecast.daily_opportunity_loss.series[0],
          },
          {
            label: "Battery Loss (Battery > 80%)",
            color: "warning",
            data: forecast.daily_batteryloss80.series[0],
          },
          {
            label: "Battery Loss (Battery < 20%)",
            color: "error",
            data: forecast.daily_batteryloss20.series[0],
          },
        ],
      });
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchTaskStatus() {
      const accessToken = await getToken();
      const statusResponse = await (
        await fetch(`${serverBaseURL}caculate_status`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      ).json();
      console.log(statusResponse);
      setStatus(statusResponse.task_status);
      setStartTime(statusResponse.start_time.split(".")[0]);
      setEndTime(statusResponse.end_time.split(".")[0]);
      setProcessTime(statusResponse.process_time);
    }
    fetchTaskStatus();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <br />
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">Latest Task</MDTypography>
              <MDTypography variant="h6">Status: {status}</MDTypography>
              <MDTypography variant="h6">startTime: {startTime}</MDTypography>
              <MDTypography variant="h6">endTime: {endTime}</MDTypography>
              <MDTypography variant="h6">processTime: {processTime}</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">Historical Analysis</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="battery_alert"
                  title="Opportunity Loss"
                  description="Battery zero"
                  value={trendOpportunityLoss}
                  color="warning"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="battery_5_bar"
                  title="Battery Loss"
                  description="Battery > 80%"
                  value={trendBatteryLoss80}
                  color="warning"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="battery_1_bar"
                  title="Battery Loss"
                  description="Battery < 20%"
                  value={trendBatteryLoss20}
                  color="warning"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="warning"
                  title="Non-optimal PV Loss"
                  description="Red"
                  value={trendOptimizePanelLossRed}
                  color="warning"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="warning"
                  title="Non-optimal PV Loss"
                  description="Yellow"
                  value={trendOptimizePanelLossYellow}
                  color="warning"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="warning"
                  title="Non-optimal PV Loss"
                  description="Total"
                  value={trendOptimizePanelLossTotal}
                  color="warning"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="eco"
                  title="CO2 Emissions"
                  description="Backup energy"
                  value={trendImpactOfAlternativeEnergy}
                  color="warning"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="ev_station"
                  title="Power Generation"
                  description="Daily max"
                  value={trendPowerGenerationDailyMax}
                  color="warning"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="ev_station"
                  title="Power Generation"
                  description="Monthly max"
                  value={trendPowerGenerationMonthlyMax}
                  color="warning"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="ev_station"
                  title="Power Generation"
                  description="Daily average"
                  value={trendPowerGenerationDailyAverage}
                  color="warning"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="ev_station"
                  title="Power Generation"
                  description="Monthly average"
                  value={trendPowerGenerationMonthlyAverage}
                  color="warning"
                />
              </MDBox>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultLineChart
                  icon={{ color: "warning", component: "show_chart" }}
                  title="Trend Graph"
                  description="&nbsp;"
                  chart={trendGraphData}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <br />
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">Forecasting</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="battery_alert"
                  title="Opportunity Loss"
                  description="Battery zero"
                  value={forecastOpportunityLoss}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="battery_5_bar"
                  title="Battery Loss"
                  description="Battery > 80%"
                  value={forecastBatteryLoss80}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="battery_1_bar"
                  title="Battery Loss"
                  description="Battery < 20%"
                  value={forecastBatteryLoss20}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="ev_station"
                  title="Power Generation"
                  description="Daily max"
                  value={forecastPowerGenerationDailyMax}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="ev_station"
                  title="Power Generation"
                  description="Daily average"
                  value={forecastPowerGenerationDailyAverage}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="grade"
                  title="Overall Accuracy"
                  description="&nbsp;"
                  value="Insufficient data for analysis"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="grade"
                  title="Average Accuracy"
                  description="Red"
                  value="Insufficient data for analysis"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="grade"
                  title="Mean Absolute Error"
                  description="&nbsp;"
                  value="Insufficient data for analysis"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="grade"
                  title="Average Accuracy"
                  description="Yellow"
                  value="Insufficient data for analysis"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultInfoCard
                  icon="grade"
                  title="Average Accuracy"
                  description="Green"
                  value="Insufficient data for analysis"
                />
              </MDBox>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={1.5} mt={1.5}>
                <DefaultLineChart
                  icon={{ component: "show_chart" }}
                  title="Forecast Graph"
                  description="&nbsp;"
                  chart={forecastGraphData}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
