import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import { useState } from "react";

import Grid from "@mui/material/Grid";

import { useLocation } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { googleMapKey } from "common/settings";

function Map() {
  const location = useLocation();
  const iconBase = "http://maps.google.com/mapfiles/ms/icons/";
  const [markers] = useState(
    location.state.markerData.map((prop, key) => {
      const color = prop[0];
      const lat = prop[1];
      const lng = prop[2];
      let iconUrl;
      if (color === "Red") {
        iconUrl = `${iconBase}red-dot.png`;
      } else if (color === "Yellow") {
        iconUrl = `${iconBase}yellow-dot.png`;
      } else {
        iconUrl = `${iconBase}green-dot.png`;
      }
      return (
        <Marker
          key={`marker-${key}`}
          position={{ lat: Number(lat), lng: Number(lng) }}
          icon={iconUrl}
        />
      );
    })
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <br />
        <Grid container alignItems="center">
          <Grid item xs={12} sm={12} md={12}>
            <LoadScript googleMapsApiKey={googleMapKey}>
              <GoogleMap
                center={{
                  lat: Number(location.state.markerData[0][1]),
                  lng: Number(location.state.markerData[0][2]),
                }}
                zoom={10}
                options={{ scrollwheel: false }}
                mapContainerStyle={{ height: `600px`, borderRadius: "6px", overflow: "hidden" }}
              >
                {markers}
              </GoogleMap>
            </LoadScript>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Map;
