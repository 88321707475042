/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

function configs(labels, ydatasets, y2datasets) {
  let ydatasetsArray, y2datasetsArray;
  if (!Array.isArray(ydatasets)) {
    ydatasetsArray = [ydatasets];
  } else {
    ydatasetsArray = ydatasets;
  }
  console.log(y2datasets);
  if (!Array.isArray(y2datasets)) {
    y2datasetsArray = [y2datasets];
  } else {
    y2datasetsArray = y2datasets;
  }
  console.log(y2datasetsArray);
  ydatasetsArray = ydatasetsArray.map((i) => ({
    label: i.label,
    tension: 0.4,
    borderWidth: 0,
    borderRadius: 4,
    borderSkipped: false,
    backgroundColor: i.backgroundColor,
    data: i.data,
    maxBarThickness: 6,
    yAxisID: "y",
    type: "bar",
  }));
  y2datasetsArray = y2datasetsArray.map((i) => ({
    label: i.label,
    tension: 0.4,
    borderWidth: 0,
    borderRadius: 4,
    borderSkipped: false,
    backgroundColor: i.backgroundColor,
    data: i.data,
    maxBarThickness: 6,
    yAxisID: "y2",
    type: "line",
  }));
  console.log(y2datasetsArray);
  ydatasetsArray = ydatasetsArray.concat(y2datasetsArray);
  console.log(ydatasetsArray);
  return {
    data: {
      labels,
      datasets: ydatasetsArray,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          position: "left",
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            color: "rgba(255, 255, 255, .2)",
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 500,
            beginAtZero: true,
            padding: 10,
            font: {
              size: 14,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
            color: "#fff",
          },
          stacked: true,
        },
        y2: {
          position: "right",
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            color: "rgba(255, 255, 255, .2)",
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 500,
            beginAtZero: true,
            padding: 10,
            font: {
              size: 14,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
            color: "#fff",
          },
          stacked: true,
        },
        x: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            color: "rgba(255, 255, 255, .2)",
          },
          ticks: {
            display: true,
            color: "#f8f9fa",
            padding: 10,
            font: {
              size: 14,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
          },
          stacked: true,
        },
      },
    },
  };
}

export default configs;
