import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return (
        <MDBox>
          <h5>● OVERVIEW</h5>
          <p>Check data of all sites combined.</p>
          <h5>● IMPACT METRICS</h5>
          <p>Impact measurement based on the GOGLA metrics framework.</p>
          <h5>● ANALYSIS</h5>
          <p>
            Analytics based on the historical data of your sites. Based on performance data, each
            site will be classified into red, yellow, or green status. You can sort the list from
            best to worst performing sites (and vice versa). You can also find the optimal PV size
            for each site.
          </p>
          <h5>● PREDICTIVE ANALYSIS</h5>
          <p>
            Daily status forecast of your sites (for the next two weeks) — at a glance, you can
            check the number of red, yellow, and green status. For example, you can decide to take
            preemptive measures if you see numbers of ‘red’ sites on the following day. The ‘Site
            Forecasting Table’ will show you each site’s profile and status (daily forecast for the
            next two weeks). You can sort the table by date, site name, system status, area, and so
            on.
          </p>
          <h5>● CLASSIFICATION</h5>
          <p>The classification map will indicate optimal PV sizes for each area.</p>
          <h5>● REGRESSION</h5>
          <p>You can check optimal PV sizes for each area.</p>
          <br />
          <br />
          <h4>[EnerScale Beta]</h4>
          <p>
            EnerScale will not be operating on a cloud GPU environment during the beta period, which
            comes at a high cost. This is to ensure agile product iterations and to minimize cost
            burdens on beta partners. Hence, it will require a few extra steps for you to prepare
            and process data. If you encounter any issues, please reach out to us at
            support@enerscale.co. Our customer support team will help you at every step.
          </p>
        </MDBox>
      );
    case 1:
      return (
        <MDBox>
          <p>To use EnerScale BETA, please prepare the following data:</p>
          <ul>
            <li>
              Meta data (<i>see example</i>)
            </li>
            <table border="1">
              <tr>
                <th>NO</th>
                <th>COUNTRY</th>
                <th>COORDINATE</th>
                <th>
                  SITE
                  <br />
                  ID
                </th>
                <th>
                  PANEL
                  <br />
                  OUTPUT
                </th>
                <th>
                  DAILY
                  <br />
                  CONSUMPTION
                </th>
                <th>
                  BATTERY
                  <br />
                  CAPACITY
                </th>
                <th>VOLTAGE</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Singapore</td>
                <td>1.306,103.714</td>
                <td>1</td>
                <td>2.5</td>
                <td>0.5</td>
                <td>100</td>
                <td>12</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Singapore</td>
                <td>1.506,101.324</td>
                <td>2</td>
                <td>1.0</td>
                <td>0.25</td>
                <td>200</td>
                <td>24</td>
              </tr>
            </table>
            <br />
            <table border="1">
              <tr>
                <th>
                  NOMINAL
                  <br />
                  AMP
                </th>
                <th>APPLIANCE 1</th>
                <th>
                  APPLIANCE 1
                  <br />
                  CONSUMPTION / DAY (kWh)
                </th>
                <th>APPLIANCE 2</th>
                <th>
                  APPLIANCE 2
                  <br />
                  CONSUMPTION / DAY (kWh)
                </th>
              </tr>
              <tr>
                <td>16.6</td>
                <td>Lighting</td>
                <td>0.01</td>
                <td>Fan</td>
                <td>0.02</td>
              </tr>
              <tr>
                <td>33.3</td>
                <td>Lighting</td>
                <td>0.02</td>
                <td>Fan</td>
                <td>0.04</td>
              </tr>
            </table>
            <br />
            <br />

            <li>
              Historical data (<i>see example</i>)
            </li>
            <table border="3">
              <tr>
                <th>NO</th>
                <th>TIMESTAMP</th>
                <th>
                  SITE
                  <br />
                  ID
                </th>
                <th>
                  BATTERY
                  <br />
                  MONITOR
                </th>
                <th>
                  SOLAR
                  <br />
                  CHARGER
                </th>
              </tr>
              <tr>
                <td>1</td>
                <td>1/1/2021 7:15:00 PM</td>
                <td>1</td>
                <td>6.12</td>
                <td>0.64</td>
              </tr>
              <tr>
                <td>2</td>
                <td>1/1/2021 7:16:00 PM</td>
                <td>1</td>
                <td>6.13</td>
                <td>0.34</td>
              </tr>
              <tr>
                <td>・・</td>
                <td>・・</td>
                <td>・・</td>
                <td>・・</td>
                <td>・・</td>
              </tr>
              <tr>
                <td>Max_No</td>
                <td>12/31/2021 11:59:00 PM</td>
                <td>Max_Site_No</td>
                <td>32.5</td>
                <td>0.56</td>
              </tr>
            </table>
          </ul>
        </MDBox>
      );
    case 2:
      return (
        <MDBox>
          <p>Go to the ‘File Manager’ section and upload your data</p>
        </MDBox>
      );
    default:
      return null;
  }
}

function Tutorial() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Introduction", "Preparation", "Upload"];
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={8}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Tutorial
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(activeStep)}
                  <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton variant="outlined" color="dark" onClick={handleBack}>
                        Previous
                      </MDButton>
                    )}
                    {isLastStep ? (
                      <MDBox />
                    ) : (
                      <MDButton variant="gradient" color="dark" onClick={handleNext}>
                        next
                      </MDButton>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tutorial;
