import variables from "common/variables";

function getToken() {
  return "";
}

function storeToken(token) {
  if (variables.isRememberMe) {
    localStorage.setItem("token", token);
  } else {
    sessionStorage.setItem("token", token);
  }
  const decodedToken = jwtDecode(token);
  const roles = decodedToken.roles.split(",");
  let currentRole = "borker";
  const operatorRoles = roles.filter((x) => x === "operator");
  if (operatorRoles.length > 0) {
    currentRole = "operator";
  }
  localStorage.setItem("currentRole", currentRole);
}

function getName() {
  return "";
}

function deleteToken() {
  localStorage.removeItem("token");
  sessionStorage.removeItem("token");
  localStorage.removeItem("currentRole");
}

function getCurrentRole() {
  return localStorage.getItem("currentRole");
}

function currentUserRoles() {
  return "";
}

function getId() {
  const token = getToken();
  const decodedToken = jwtDecode(token);
  return decodedToken.user_id;
}

export { getToken, storeToken, getCurrentRole, currentUserRoles, deleteToken, getName, getId };
