import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

function Lifetime() {
  const location = useLocation();
  const [lifetimeData, setLifetimeData] = useState({});
  const [fpLifetimeData, setFpLifetimeData] = useState({});
  console.log(location.state.prop.lifetime_series);

  useEffect(() => {
    const yearlabels = [];
    for (let i = 0; i <= location.state.prop.years; i += 1) {
      yearlabels.push(location.state.prop.installed_year + i);
    }

    setLifetimeData({
      labels: yearlabels,
      datasets: [
        {
          label: "degradation",
          color: "dark",
          data: location.state.prop.lifetime_series,
        },
      ],
    });

    const fpYearlabels = [];
    for (let i = 0; i <= location.state.prop.fp_years; i += 1) {
      fpYearlabels.push(location.state.prop.installed_year + i);
    }

    setFpLifetimeData({
      labels: fpYearlabels,
      datasets: [
        {
          label: "degradation",
          color: "dark",
          data: location.state.prop.fp_lifetime_series,
        },
      ],
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <MDBox mt={8}>
          <MDTypography mt={2} variant="h3" align="center" sx={{ color: "#484731" }}>
            Lifetime
          </MDTypography>
        </MDBox>
        <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
          <MDBox width="60rem">
            <GradientLineChart title="" chart={lifetimeData} />
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox pb={3}>
        <MDBox mt={8}>
          <MDTypography mt={2} variant="h3" align="center" sx={{ color: "#484731" }}>
            FP Lifetime
          </MDTypography>
        </MDBox>
        <MDBox mt={4} display="flex" justifyContent="center" alignItems="center">
          <MDBox width="60rem">
            <GradientLineChart title="" chart={fpLifetimeData} />
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Lifetime;
